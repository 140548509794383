// Layout
.container-fluid {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0;
	padding-left: 0;
}

.page-wrapper {
	position: relative;
	transition: transform .35s;

	.mmenu-active & {
		transform: translateX(250px);
	}
}

.main {
	flex: 1 1 auto;
}

.row.row-sm {
	margin-right: -10px;
	margin-left: -10px;

	[class*='col-'] {
		padding-right: 10px;
		padding-left: 10px;
	}
}

.ajax-overlay {
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(0, 0, 0, .6);
	z-index: 1041;
}

@include mq(xl) {
	.padding-left-lg {
		padding-left: 35px;
	}

	.padding-right-lg {
		padding-right: 35px;
	}

	.col-xl-5col {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}

	.col-xl-7col {
		-ms-flex: 0 0 14.2857%;
		flex: 0 0 14.2857%;
		max-width: 14.2857%;
	}

	.col-xl-8col {
		-ms-flex: 0 0 12.5%;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 750px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 981px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1170px;
	}
}
.width--modifier{
	max-width: 1295px!important;
}
.width--modifier .col-3 {
    padding: 0px 4.5px!important;
}