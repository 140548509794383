// Product Page 4
.product-single-grid {
	margin-bottom: 6rem;

	.product-single-gallery {
		padding: 0 .75rem;

		.product-item {
			margin-bottom: 1.5rem;
			padding: 0 .75rem;
		}
	}

	.inner {
		position: relative;
	}

	.prod-full-screen {
		right: 1rem;
		bottom: 1rem;
	}

	.product-single-details {
		.product-title {
			margin-top: 1.5rem;
			font-size: 2.8rem;
		}

		.ratings-container {
			margin-bottom: 5rem;
		}

		.price-box {
			margin-bottom: 3.8rem;
		}

		.product-price {
			font-size: 3.1rem;
		}

		.old-price {
			font-size: 2.2rem;

			& + .product-price {
				margin-left: 1.5rem;
			}
		}

		.product-action {
			margin-bottom: 3.8rem;
		}

		.paction {
			height: 59px;
		}
	}

	.add-cart {
		min-width: 270px;
		margin-left: 0;

		&:before {
			font-size: 2.1rem;
		}

		span {
			font-size: 1.6rem;
		}
	}

	.product-filters-container {
		margin-bottom: 4rem;
	}

	.product-single-filter {
		padding-bottom: 2.1rem;

		.config-swatch-list {
			li {
				margin-right: .5rem;
				margin-bottom: 0;
			}

			a {
				width: 3rem;
				height: 3rem;
			}
		}
	}
}

.product-size-content {
	img {
		display: block;
		width: auto;
		max-width: 100%;
		margin-right: auto;
		margin-bottom: 2rem;
		margin-left: auto;
	}
}

.table.table-size {
	thead tr th,
	tbody tr td {
		border: 0;
		color: #21293c;
		font-size: 1.5rem;
		letter-spacing: .005em;
		text-transform: uppercase;
	}

	thead {
		tr {
			th {
				padding: 2.8rem 1.5rem 1.7rem;
				background-color: #f4f4f2;
				font-weight: 600;
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 1rem 1.5rem;
				background-color: #fff;
				font-weight: 700;

				&:first-child {
					font-weight: 600;
				}
			}

			&:nth-child(2n) {
				td {
					background-color: #ebebeb;
				}
			}
		}
	}
}

@include mq(lg) {
	.table.table-size {
		thead tr th {
			padding-top: 4.2rem;
			padding-bottom: 2.8rem;
		}

		thead tr th,
		tbody tr td {
			padding-right: 3.5rem;
			padding-left: 3.5rem;
		}
	}
}

@media (max-width: 912px) and (min-width: 768px) {
	.product-single-grid .add-cart {
		min-width: 100%;
	}
}

@include mq(sm, max) {
	.product-single-grid {
		.product-single-gallery {
			.row {
				margin-right: -10px;
				margin-left: -10px;
			}

			[class*='col-'] {
				padding-right: 10px;
				padding-left: 10px;
			}
		}
	}
}
