#header .header-nav-features .header-nav-features-dropdown {
    opacity: 0;
    top: -10000px;
    position: absolute;
    -webkit-box-shadow: 0 10px 30px 10px rgb(0 0 0 / 5%);
    box-shadow: 0 10px 30px 10px rgb(0 0 0 / 5%);
    background: #FFF;
    padding: 17px;
    min-width: 300px;
    z-index: 10000;
    right: 100%;
    margin-right: -25px;
    margin-top: 30px;
    -webkit-transition: opacity .2s ease-out;
    -o-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
    pointer-events: none;
}
#header .header-nav-features .header-nav-features-dropdown.show {
    opacity: 1;
    top: auto;
    pointer-events: all;
}
#header .header-nav-features .header-nav-features-cart .header-nav-features-dropdown {
    padding: 20px;
}
