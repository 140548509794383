// Social Icons
.social-icons {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.social-icon {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 3.2rem;
	height: 3.2rem;
	background-color: $primary-color;
	color: #fff;
	font-size: 1.4rem;
	text-decoration: none;
	opacity: 1;

	& + .social-icon {
		margin-left: .5rem;
	}

	&:hover,
	&:focus {
		color: #fff;
		text-decoration: none;
		opacity: .85;
	}

	&.social-facebook {
		background-color: #3b5a9a;
		color: #fff;
	}

	&.social-twitter {
		background-color: #1aa9e1;
		color: #fff;
	}

	&.social-linkedin {
		background-color: #0073b2;
		color: #fff;
	}

	&.social-gplus {
		background-color: #dd4b39;
		color: #fff;
	}

	&.social-mail {
		background-color: #dd4b39;
		color: #fff;
	}
}