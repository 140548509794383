// Direction
$dir: ltr !default;

// Default $dir if not valid
@if $dir != ltr and $dir != rtl {
	$dir: ltr;
}

@function if-ltr($if, $else: null) {
	@if $dir != rtl {
		@return $if;
	} @else {
		@return $else;
	}
}

@function if-rtl($if, $else: null) {
	@return if-ltr($else, $if);
}

$left: if-ltr(left, right);
$right: if-ltr(right, left);

// Sections
html {
	@each $value in $colors-list {
		$name: nth($value, 1);
		$color: nth($value, 2);
		$color-inverse: nth($value, 3);

		section.section-#{$name} {
			background-color: $color !important;
			border-color: darken($color, 5%) !important;
			color: $color-inverse;

			h1, h2, h3, h4, h5, h6 {
				color: $color-inverse;
			}

			p {
				color: $color-inverse;
			}
		}

		section.section-#{$name}-scale-2 {
			background-color: darken($color, 10%) !important;
			border-color: darken($color, 15%) !important;

			.sort-source {
				&.sort-source-style-2 {
					> li {
						&.active {
							> a {
								&:after {
									border-top-color: darken($color, 10%);
								}
							}
						}
					}
				}
			}
		}
	}
}

html {
	@each $value in $colors-list {
		$name: nth($value, 1);
		$color: nth($value, 2);
		$color-inverse: nth($value, 3);

		.btn-outline {
			&.btn-#{$name} {
				@include buttonOutlineBackground($color, $color-inverse);
			}
		}

		.show > .btn-outline.btn-#{$name}.dropdown-toggle {
			color: $color-inverse !important;
			background-color: $color !important;
			border-color: $color !important;
		}
	}
}
.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
	/* align horizontal */
	align-items: center !important;
}

.home-top {
    margin-top: 46px!important;
}
@media screen and (max-width:767px) {
	span.coredna-brand.footerGlobal__logo {
		justify-content: center;
	}
}