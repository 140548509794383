// Menu
.menu,
.menu * {
	margin: 0;
	padding: 0;
	list-style: none;
}

.menu {
	@include clearfix;

	li {
		position: relative;

		&.megamenu-container {
			position: static;
		}
	}

	> li {
		float: left;
	}

	ul {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 99;

		ul {
			top: -1rem;
			left: 100%;
		}
	}

	.megamenu {
		display: none;
		position: absolute;
		left: calc((100% - 760px) / 2);
		z-index: 999;
		transition: all .3s ease-out;

		&.megamenu-fixed-width {
			right: auto;
			left: 0;
			width: 640px;
		}
	}

	a {
		display: block;
		position: relative;
		text-decoration: none;
	}
}

.menu li:hover > ul,
.menu li.show > ul,
.menu li:hover > .megamenu,
.menu li.show > .megamenu {
	display: block;
	opacity: 1;
}

// Menu Skin
.menu {
	line-height: 1.8;

	li {
		padding-right: 1rem;
		padding-left: 1rem;

		a {
			display: block;
			padding: .7rem 1rem .7rem 0;
			transition: all .2s ease-out;
			color: #696969;
			font-size: 1.2rem;
			font-weight: 600;
			text-transform: uppercase;
			white-space: nowrap;
		}

		.menu-title {
			margin-top: 1.25rem;
			margin-bottom: 0;
			color: #434d53;
			letter-spacing: -.01rem;
			text-transform: uppercase;
			font-weight: 700;
			// font-family: "Poppins";

			a {
				padding: .7rem;
				color: #434d53;
				font-size: 1.3rem;
				font-weight: 700;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}

	> li {
		padding-right: 0;
		padding-left: 0;
		margin: 1.1rem 2.5rem 1.1rem 0;

		&:last-of-type {
			margin-right: 0;
		}

		> a {
			font-weight: 400;
			font-size: 11px;
			font-family: Poppins;
			background-color: transparent;
			color: $color-black;
			&:hover{
				color: $color-black!important;
				text-decoration: underline;
			}
		}

		&.special-effect {
			margin-right: 3rem;

			> a {
				color: $primary-color;
			}
		}

		&.buy-effect {
			margin-right: 0;
		}
	}

	&.sf-arrows {
		> li {
			> a.sf-with-ul {
				padding-right: 2.8rem;
			}
		}

		.sf-with-ul {
			position: relative;

			&:after {
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 1.5rem;
				margin-top: -1px;
				transform: translateY(-50%);
				font-family: 'porto';
				font-size: 1.3rem;
				line-height: 1;
				content: '\e81c';
			}
		}

		ul {
			.sf-with-ul {
				&:after {
					right: 1rem;
					content: '\e81a';
				}
			}
		}
	}

	.megamenu,
	ul {
		background-color: #fff;
		box-shadow: 0 29px 29px rgba(0, 0, 0, .1);
	}

	ul {
		min-width: 240px;
		padding: 1rem;

		li:hover,
		li:active,
		li.show {
			background-color: #f4f4f4;
		}
	}

	.megamenu {
		padding: 1rem;

		ul {
			display: block;
			position: static;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			min-width: 0;
			margin: 0 0 1.25rem;
			padding: 0;
			box-shadow: none;
		}

		li {
			padding-left: 0;

			a {
				padding-left: 1rem;
			}
		}

		.banner {
			width: 100%;
			height: 100%;
			margin-bottom: 0;
			background-color: transparent;

			a {
				width: 100%;
				height: 100%;
				padding: 0;
				margin: 0;
			}

			img {
				position: absolute;
				height: 100%;
			}

			img.product-promo {
				padding: 1rem;
			}
		}

		li:hover,
		li.show,
		li.active {
			background-color: transparent;

			a {
				background-color: #f4f4f4;
				color: $primary-color-dark;
			}
		}
	}
}

.menu {
	> li:hover,
	> li.show,
	> li.active {
		background-color: transparent;
		text-decoration: underline;

		> a {
			color: $primary-color;
		}
	}

	&.sf-arrows {
		> li.show {
			> a.sf-with-ul:before {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.show > ul,
	.show > .megamenu {
		opacity: 1 !important;
	}
}

.tip {
	color: #fff;
	position: relative;
	display: inline-block;
	font-size: 1rem;
	font-family: $font-family;
	font-weight: 400;
	padding: .3rem .4rem;
	z-index: 1;
	border-radius: .2rem;
	line-height: 1;
	margin: -2px 0 0 1rem;
	vertical-align: middle;
	text-transform: uppercase;

	&:before {
		position: absolute;
		top: 50%;
		right: 100%;
		left: auto;
		margin-top: -3px;
		border: 3px solid transparent;
		content: '';
	}

	&.tip-new {
		background-color: #0fc567;
	}

	&.tip-new:before {
		border-right-color: #0fc567;
	}

	&.tip-hot {
		background-color: #eb2771;
	}

	&.tip-hot:before {
		border-right-color: #eb2771;
	}
}

.menu > li > a {
	.tip {
		position: absolute;
		top: -4px;
		right: 22px;
		margin: 0;

		&:before {
			position: absolute;
			top: 100%;
			left: 3px;
			width: 3px;
			height: 3px;
			margin: 0;
			border-color: transparent;
		}

		&.tip-new:before {
			border-top-color: #0cc485;
		}

		&.tip-hot:before {
			border-top-color: #eb2771;
		}
	}
}
@media all and (min-width:992px){

.menu{
	li{
		ul{
			li{
				a{
					padding: 14px 0px;
					font-size: 1rem;
				}
			}
		}
		.megamenu{
			.menu-title{
				font-size: 1rem;
			}
			ul{
				a{
					margin-top: 12px;
					.text-wrap{
						margin-top: 6px !important;
    					line-height: 1.4;
					}
				}
			}
		}
	}

}
.acount--menu{
	.simple-search{
		input[type="search"]{
			font-size: 1.3rem;
			padding-right: 0;
		}
		.icon-search-1{
			font-size: 19px !important;
    		margin-top: -14px !important;
		}
	}
}

}