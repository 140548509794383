// Info Boxes
.info-boxes-container {
	background-color: $secondary-color-dark;
	margin-bottom: 3rem;

	.container,
	.container-fluid {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-align: center;
		align-items: center;
		padding: 0;
	}
}

.info-box {
	text-align: center;
	padding: 1.1rem 1.5rem;
	width: 100%;
	color: #c8cdcf;

	i {
		display: block;
		color: $primary-color;
		font-size: 4.769rem;
		min-width: 4.769rem;
		margin-bottom: 4rem;
		text-align: center;

		&:before {
			width: auto;
			margin: 0;
		}
	}

	.info-title {
		color: $primary-color-dark1;
		font: 700 1.8rem/1.1 $font-family;
		letter-spacing: -.01rem;
		text-transform: uppercase;
		margin-bottom: 1rem;
	}

	.info-subtitle {
		color: $primary-color-dark1;
		font: 600 1.7rem/1.1 $font-family;
		letter-spacing: -.01rem;
	}

	p {
		color: #777;
		margin-bottom: 0;
		font-size: 1.4rem;
		line-height: 2.6;
		letter-spacing: .05em;
		font-weight: 400;
	}

	& + .info-box {
		border-top: 1px solid $secondary-color;
	}
}

@include mq(md) {
	.info-boxes-container {
		margin-bottom: 4rem;

		.container,
		.container-fluid {
			-ms-flex-direction: row;
			flex-direction: row;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
	}
	.info-box {
		padding-top: 7rem;
		padding-bottom: 7rem;
		justify-content: center;

		&:first-child {
			justify-content: flex-start;
		}

		& + .info-box {
			border-top: 0;
		}
	}
}

@media (max-width: 767px) {
	.info-boxes-container {
		.container {
			width: 100%;
			max-width: none;
		}
	}
}