// Product Page
.paction {
	position: relative;
	display: -ms-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.8rem;
	font-family: 'porto';
	color: #0e2f40;
	background-color: #ffffff;
	border: 1px solid #d4d4d4;
	transition: all .3s;
	text-decoration: none;
	text-align: center;
	min-width: 35px;
	height: 35px;

	span {
		display: none;
		padding-right: .1rem;
		padding-left: .4rem;
		font-size: 1.2rem;
		letter-spacing: .02rem;
	}

	& + .paction {
		margin-left: .7rem;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		color: #302e2a;
		border-color: #302e2a;
	}
}

.add-wishlist {
	&:before {
		content: '\e889';
	}
}

.add-compare {
	&:before {
		content: '\e88f';
	}
}

.add-cart {
	color: #2b2b2d;
	font-family: $second-font-family;
	font-weight: 400;
	text-transform: uppercase;
	padding: .85rem .5rem;
	min-width: 105px;
	z-index: 2;

	&:before {
		display: inline-block;
		line-height: 1;
		margin-top: 2px;
		font-size: 1.9rem;
		content: '\e87f';
		font-family: 'porto';
	}

	span {
		display: inline-block;
		padding-top: 1px;
		font-family: "Oswald", sans-serif;
	}

	&:hover,
	&:focus {
		border-color: $primary-color;
		background-color: $primary-color;
		color: #fff;
	}
}

.product-single-container {
	margin-bottom: 3rem;
}

.product-single-details {
	font-family: $second-font-family;

	.product-title {
		margin-bottom: 1.1rem;
		color: #21293c;
		font-size: 2.5rem;
		font-weight: 600;
		letter-spacing: -.01em;
	}

	.price-box {
		margin-bottom: 2.4rem;
		color: $primary-color;
		font-weight: 700;
	}

	.product-price {
		font-size: 2.1rem;
		font-weight: 700;
		color: $primary-color;
	}

	.old-price {
		font-size: 1.6rem;
		font-weight: 600;

		& + .product-price {
			margin-left: 1rem;
		}
	}

	.ratings-container {
		margin-bottom: 2.1rem;

		.product-ratings,
		.ratings {
			font-size: 1.4rem;
		}

		.product-ratings {
			height: 14px;
		}
	}

	.rating-link {
		margin-left: 1rem;
		color: #90939a;
		font-weight: 500;
		letter-spacing: .025em;
	}

	.product-desc {
		margin-bottom: 2.7rem;
		padding-bottom: 2.7rem;
		border-bottom: 1px solid #dae2e6;
		font-size: 1.4rem;
		letter-spacing: .005em;
		line-height: 1.92;

		// p {
		//   max-width: 360px;
		// }
	}

	.product-action {
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
		margin-bottom: 3.1rem;
	}

	.sticky-header {
		height: auto;

		.sticky-detail {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-direction: row;
			flex-direction: row;
			-ms-flex-align: center;
			align-items: center;
		}

		.product-title {
			margin-right: 2rem;
			margin-bottom: 0;
			color: #777;
			font-size: 16px;
			font-weight: 600;
		}

		.price-box {
			margin-top: 1rem;
			margin-bottom: 0;
			padding: 0;
			border: 0;
			color: #2b2b2d;
		}

		.ratings-container {
			margin-bottom: 3.2rem;
		}
	}

	.container {
		display: none;
		-ms-flex-align: center;
		align-items: center;

		img {
			max-width: 5rem;
			max-height: 5rem;
			margin-right: 2rem;
		}

		.add-cart {
			margin-left: auto;
			margin-bottom: 0;
		}
	}

	.sticky-header.fixed-nav .container {
		display: -ms-flexbox;
		display: flex;
		padding: 1.5rem;
	}

	.product-single-qty, .paction {
		margin: 0 1rem 1rem 0;
	}

	.paction {
		height: 43px;
		min-width: 41px;
	}
}

.product-both-info {
	.product-single-details {
		margin-top: 0;

		.product-desc {
			border-bottom: 0;
		}
	}
}

.product-single-gallery {
	a {
		display: block;
	}

	img {
		display: block;
		width: 100%;
		max-width: none;
		height: auto;
	}

	.owl-theme .owl-nav,
	.owl-theme .owl-nav {
		color: #000;
		font-size: 32px;
	}

	.owl-prev,
	.owl-next {
		margin: 0 2rem !important;
		transition: all .5s;
		opacity: 0;
	}

}

.prod-thumbnail {
	padding: .5rem 1rem;

	img {
		width: 100%;
		cursor: pointer;
	}

	> div {
		padding: .5rem;
	}

	.active img, img:hover {
		border: 2px solid #21293c;
	}
}

.transparent-dots {
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	top: 1rem;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 80px;
	margin: 0;
	padding: 0;
	opacity: .5;
	z-index: 99;

	.active img, img:hover {
		border: 1px solid $primary-color;
	}
}

.product-slider-container {
	position: relative;
}

.prod-full-screen {
	position: absolute;
	right: 2rem;
	bottom: 1.7rem;
	transition: all .5s;
	outline: none;
	opacity: 0;
	z-index: 1;

	i {
		color: #000;
		font-size: 1.4rem;
		cursor: pointer;
	}
}

.product-item:hover {
	.prod-full-screen,
	.owl-prev,
	.owl-next {
		opacity: 1;
	}
}

.prod-thumbnail {
	img {
		display: block;
	}

	.owl-dot {
		box-sizing: border-box;
	}
}

.single-info-list {
	margin-bottom: 2rem;
	padding: 0;
	font-size: 1.4rem;
	line-height: 1.5;
	text-transform: uppercase;

	li {
		margin-bottom: 1rem;
		color: #7b858a;
		letter-spacing: .005em;

		strong {
			color: #4c4c4c;
		}
	}
}

.product-single-qty {
	max-width: 104px;
	max-height: 43px;

	.bootstrap-touchspin {
		&.input-group {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
			-ms-flex-align: stretch;
			align-items: stretch;
			max-width: 100%;
			max-height: 43px;
			padding-right: 0;
		}

		.form-control {
			height: 43px;
			padding: 1rem .2rem;
			color: #21293c;
			font-size: 1.5rem;
			letter-spacing: .05em;
			box-shadow: none;
		}

		.form-control,
		.form-control:not(:focus) {
			border-color: #dae2e6;
		}
	}

	.horizontal-quantity {
		height: 43px;
	}

	.btn {
		display: -ms-inline-flexbox;
		display: inline-flex;
		position: relative;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		min-width: 31px;
		margin: 0;
		padding: .5rem;
		font-family: $font-family;
		font-size: 2.4rem;
		line-height: 1;
		z-index: 2;

		&.btn-outline {
			border-color: #dae2e6;
			color: #8798a1;
		}

		&.btn-down-icon:after,
		&.btn-up-icon:before,
		&.btn-up-icon:after {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 11px;
			height: 1px;
			margin-left: -.55rem;
			background-color: #8798a1;
			content: '';
		}

		&.btn-up-icon:before, {
			transform: rotate(90deg);
		}
	}
}

.product-filters-container {
	margin-bottom: 4.5rem;

	.product-action {
		display: flex;
	}
}

.product-single-filter {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding-bottom: 2.7rem;
	border-bottom: 1px solid #dae2e6;

	& + .product-single-filter {
		margin-top: 2.2rem;
	}

	label {
		margin-right: 1.3rem;
		margin-bottom: 0;
		color: #21293c;
		font: 600 1.5rem/1.1 $font-family;
		letter-spacing: .005em;
		text-transform: uppercase;
	}

	.config-swatch-list {
		display: inline-flex;
		margin: 0;

		li {
			margin-bottom: 0;
		}
	}

	.config-size-list li {
		margin-right: .7rem;
		margin-bottom: 0;

		a {
			min-width: 3rem;
			height: 3rem;
			border: 1px solid #dae2e6;
			background-color: #f4f4f4;
			color: #21293c;
			font-size: 1.5rem;
			font-weight: 600;
			line-height: 2.8rem;

			&:hover,
			&:focus {
				border-color: $primary-color;
				background-color: #f4f4f4;
				color: #21293c;
			}
		}

		&.active a {
			border-color: $primary-color;
			outline: none;
			background-color: #f4f4f4;
			color: #21293c;
		}
	}

	&.product-single-qty {
		max-width: 148px;
		max-height: 7.5rem;
		border-bottom: 0;
	}
}

.product-single-share {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;

	label {
		margin-right: 1.2rem;
		margin-bottom: .5rem;
		color: #7b858a;
		font: 600 1.4rem/1.1 $font-family;
		letter-spacing: .005em;
		text-transform: uppercase;
	}
}

.product-single-tabs {
	margin-bottom: 5.5rem;

	.tab-pane {
		padding-top: 3.5rem;
		color: #7b858a;
		line-height: 1.92;
	}

	.sticky-header {
		height: auto;
	}
}

.scrolling-box .tab-pane + .tab-pane {
	margin-top: 3.5rem;
	border-top: 2px solid #dae2e6;
}

.product-desc-content {
	p {
		margin-bottom: 2.2rem;
	}

	ul,
	ol {
		margin-bottom: 2.2rem;
		padding-left: 4rem;

		li {
			margin-bottom: .4rem;

			i {
				margin-right: 2rem;
				color: #21293c;
				font-size: 1.5rem;

				&:before {
					margin: 0;
				}
			}
		}
	}

	img.float-right,
	img.float-left {
		max-width: 50%;
	}

	.feature-box i {
		float: none;
		display: inline-block;
	}

	.feature-box-content {
		margin-left: 0;
	}
}

.product-tags-content {
	h4 {
		margin: 0 0 2rem;
		font-size: 1.8rem;
		font-weight: 700;
		text-transform: uppercase;
	}

	form {
		margin-bottom: 2rem;
	}

	.form-group {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.form-control {
		margin-right: 10px;
	}

	.btn {
		padding-top: .5rem;
		padding-bottom: .5rem;
	}
}

.collateral-box {
	ul, ol {
		margin-top: -2rem;
		margin-bottom: 2.5rem;

		li {
			padding: 2rem 0 1rem;
			border-bottom: 1px solid #dae2e6;
		}
	}
}

.ratings-table {
	width: 100%;
	margin: 20px 0 40px;
	border: 1px solid #dae2e6;
	border-spacing: 0;
	font-size: 100%;
	empty-cells: show;

	thead th,
	tbody td {
		border-right: 1px solid #dae2e6;
		border-bottom: 1px solid #dae2e6;
		line-height: 1.3;

		@media (max-width: 600px) {
			padding-left: 5px;
			padding-right: 5px;
		}

	}

	input[type=checkbox], input[type=radio] {
		margin: 0 auto;
	}

	thead {
		@media (max-width: 600px) {
			display: none;
		}

		th {
			padding: .8rem 1.5rem;
			background-color: #f6f6f6;
			color: #777;
			font-size: 1.1rem;
			font-weight: 600;
			text-align: center;
			text-transform: uppercase;
			white-space: nowrap;
			vertical-align: middle;
		}
	}

	tbody {
		td {
			padding: 1.5rem 1rem;
			font-size: 1.3rem;
			text-align: center;
			vertical-align: middle;

			&:first-child {
				font-weight: 700;
				text-align: left;
			}
		}
	}
}

.add-product-review {
	h3 {
		font-size: 1.8rem;
	}

	form {
		margin-bottom: 0;
	}
}

// Product Sidebar
.sidebar-product {
	margin-bottom: 2.8rem;

	.widget:not(:last-child) {
		margin-bottom: 2.5rem;

		&.widget-banner {
			margin-bottom: 4.6rem;
		}

		&.widget-brand {
			margin-bottom: 2.5rem;
		}
	}

	.widget-title {
		margin: 0 0 2.3rem;
		color: #21293c;
		font: 600 1.5rem/1.1 $font-family;
		text-transform: uppercase;
	}
}

.widget-brand {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	margin-top: .5rem;

	a {
		display: block;
	}
}

.widget-info {
	ul {
		margin: 0;
	}

	li {
		@include clearfix;
		padding-top: 1.8rem;
		padding-bottom: 2rem;
		border-top: 1px solid #dee5e8;
		line-height: 1;
	}

	i {
		min-width: 40px;
		margin-top: .3rem;
		margin-right: 1.4rem;
		margin-left: .7rem;
		float: left;
		color: $primary-color;
		font-size: 4rem;
		line-height: 1;

		&:before {
			margin: 0;
		}
	}

	h4 {
		margin-bottom: 0;
		color: #6b7a83;
		font: 600 1.4rem / 1.5 $font-family;
		text-transform: uppercase;
		white-space: nowrap;
		overflow: hidden;
	}
}

.widget-banner {
	a {
		display: block;
	}

	img {
		display: block;
		width: 100%;
		max-width: none;
		height: auto;
	}
}

.featured-section {
	padding-top: 3.8rem;
	padding-bottom: 6rem;
	background-color: #fff;

	&.bg-white {
		background-color: #fff;
	}

	&.pt-sm {
		padding-top: 2.5rem;
	}
}

.productPage .home-product {
	margin: 0;

	&:hover figure {
		box-shadow: none;
	}
}

.promote {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: justify;
	justify-content: space-between;

	li {
		border-top: 0;
	}

	h4 {
		color: #1d2127;
		font-weight: 400;
		letter-spacing: 0;
	}

	i {
		margin: .3rem .7rem;
	}
}

.product-single-gallery .product-item {
	position: relative;
	margin-bottom: .4rem;
}

.product-single-gallery .product-single-grid {
	margin-bottom: 3.6rem;
}

.featured-col .product-widget {
	margin-bottom: 15px;
}

.carousel-title {
	margin-bottom: 50px;
	font-size: 32px;
	font-style: italic;
	font-weight: 300;
}

@include mq(xl) {
	.widget-info {
		i {
			min-width: 40px;
			margin-right: 2.9rem;
			font-size: 4rem;
		}
	}
}

@include mq(lg) {
	.featured-section {
		padding-top: 5.8rem;
		padding-bottom: 8rem;
	}

	.product-single-container {
		.col-lg-7 {
			-ms-flex: 0 0 55.56%;
			flex: 0 0 55.56%;
			max-width: 55.56%;
		}

		.col-lg-5 {
			-ms-flex: 0 0 44.44%;
			flex: 0 0 44.44%;
			max-width: 44.44%;
		}
	}
}

@include mq(lg, max) {
	.product-single-gallery {
		margin-bottom: 3rem;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.product-all-icons.product-action {
		.product-single-qty {
			margin-right: 50%;
			margin-bottom: 1.2rem;
		}
	}
}

@include mq(md) {
	.featured-section {
		padding-top: 60px;
		padding-bottom: 7rem;
	}

	.product-both-info .product-single-share {
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
}

@include mq(sm) {
	.promote {
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.product-both-info {
		.product-top-banner {
			display: -ms-flexbox;
			display: flex;
		}

		.product-single-share {
			margin-left: auto;
		}
	}

	.product-tags-content .form-control {
		width: 250px;
	}
}
