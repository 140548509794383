// Header
.header {
	background: none;
	width: 100%;
	position: fixed;
	z-index: 9999;
	top:0px;
	.headerlogoMobile{
		display: none;
	}
	.logo--c1{
		margin-left: 15px;
	}
	
	.sticky-header.fixed{
		background: $color-muted;
		.container{
			margin-top: 0px!important;
			.midlogo--modifier {
				margin-top: 11px;
    			margin-bottom: 27px;
			}
			.leftlogo--modifier {
				margin-top: 12px;
				margin-left: 0px;
			}
			.menu{
				margin-top: 0px;
				li{
					a{
						color: $color-black!important;
					}
				}
			}
		}
	}
	.container-fluid {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		width: 100%;
		max-width: none;
	}

	.container {
		// display: flex;
		// align-items: center;
		// position: relative;
		// justify-content: space-between;
		// width: 90%;
		// max-width: none;
		// padding: 0px;
		margin-top: 24px;
	}
}

.header-left,
.header-center,
.header-right {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.header-center {
	margin: 0 auto;
}

.header-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	color: $color-black;
	padding: 14px 94px 3px;
	background-color: #f5f6f8;
	margin-top: 0px;
	min-height: 47px!important;
	border-bottom: 2px solid rgba(0, 0, 0, 0.06);
	.container{
     margin-top: 0;
	 display: flex;
	 align-items: center;
	}
	h4 {
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: .01em;
		font-family: Poppins;
		margin: 0px;
		flex: 2;
	}

	strong {
		font-weight: 800;
		letter-spacing: .01em;
		font-size: 1.3rem;
		text-transform: uppercase;
	}

	a {
		letter-spacing: .01em;
		font-weight: 400;
		font-size: 11px;
		// border-bottom: 2px solid #bbb;
		font-family: Poppins;
		line-height: 1.5rem;
		// margin-top: 1px;
		// margin-right: 4rem;
		list-style: none;
	}

	i {
		margin-top: 3px;
		font-size: 2.8rem;
		color: $primary-color-dark;
	}
}

.header-middle {
	margin-left: 94px;
	padding-bottom:0px;
	background: none;
	position: absolute;
	// border-bottom: 1px solid #e7e7e7;
	// box-shadow: 0 2.8rem 5.7rem rgba(0, 0, 0, 0.06);
	display: none;
}

.header-user {
	display: flex;
	align-items: center;
	margin-right: 2.2rem;

	i {
		font-size: 27px;
		color: black;
		border: 2px solid #f4f4f4;
		border-radius: 50%;
		margin-right: 1rem;
		padding: .3rem;
	}

	span {
		font-size: 1.2rem;
		letter-spacing: .01em;
		font-family: Poppins;
		color: $primary-color-dark;
	}

	h4 {
		font-size: 1.3rem;
		letter-spacing: .01em;
		font-family: Poppins;
	}
}

.porto-icon {
	font-size: 26px;
	margin-right: 2.2rem;
	margin-bottom: 2px;
	color: black;
	display: inline-block;
}

.logo {
	display: block;

	img {
		display: block;
		max-width: 100%;
		height: auto;
		max-height: 80px;
	}
}

.header-dropdown {
	position: relative;
	text-transform: uppercase;

	> a {
		display: -ms-inline-flexbox;
		display: inline-flex;
		position: relative;
		-ms-flex-align: center;
		align-items: center;
		padding-right: 1.6rem;
		color: #999999;
		font: normal 1.2rem 'Open Sans';

		&:after {
			display: inline-block;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			font-family: 'porto';
			font-size: 1.8rem;
			line-height: 1;
			content: '\e81c';
		}

	}

	a {
		img {
			display: inline-block;
			max-width: 16px;
			height: auto;
			margin-right: .6rem;
		}

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

.header-menu {
	position: absolute;
	top: 130%;
	left: 0;
	min-width: 100%;
	padding-top: .4rem;
	padding-bottom: .4rem;
	transition: all .25s;
	border: 1px solid #ccc;
	background-color: #fff;
	box-shadow: 0 0 2px rgba(0, 0, 0, .1);
	opacity: 0;
	visibility: hidden;
	z-index: 20;

	&:before {
		display: block;
		position: absolute;
		top: -20px;
		right: 5px;
		width: 20px;
		height: 20px;
		border: 10px solid transparent;
		border-bottom-color: #dbe0e2;
		content: '';
	}

	.header-right & {
		right: 0;
		left: auto;
	}

	.header-dropdown:hover > & {
		opacity: 1;
		visibility: visible;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			position: relative;
			margin: 0;
		}

		a {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			padding: 2px 9px;
			white-space: nowrap;
		}
	}

	a {
		color: inherit;

		&:hover,
		&:focus {
			background-color: #ccc;
			color: #fff;
		}
	}
}

.header {
	.btn-remove {
		&:before {
			display: none;
		}
	}
}

.header-dropdowns {
	.header-dropdown {
		& + .header-dropdown,
		& + .dropdown {
			margin-left: 3.4rem;

			&:before {
				position: absolute;
				left: -1.9rem;
				height: 1.9rem;
				border-right: 1px solid #e7e7e7;
				content: '';
			}
		}
	}
}

.dropdownmenu-wrapper {
	position: relative;
	background-color: #fff;
	padding: 2rem;
	border-radius: 0;
	border: 1px solid #f0f0f0;
	box-shadow: 0 29px 28px rgba(0, 0, 0, 0.1);
}

.cart-dropdown,
.compare-dropdown {
	.dropdown-menu {
		display: block;
		left: 0;
		top: 100%;
		width: 300px;
		padding-top: 10px;
		z-index: 100;
		font-size: 1.1rem;
		color: #777;
		border: 0;
		margin: 0;
		background-color: transparent;
		box-shadow: none;
		visibility: hidden;
		opacity: 0;
		transition: all .25s;

		&.show {
			opacity: 1;
			visibility: visible;
		}
	}

	&:hover {
		.dropdown-menu {
			opacity: 1;
			visibility: visible;
		}
	}
}

.compare-dropdown,
.cart-dropdown {
	.dropdown-toggle {
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

.compare-dropdown {
	.dropdown-toggle {
		text-transform: uppercase;

		i {
			margin-top: -.2rem;
			margin-right: .2rem;

			&:before {
				margin: 0;
			}
		}

		&:after {
			display: none;
		}
	}
}

.compare-products {
	margin: 0;
	padding: 0;
	list-style: none;

	.product {
		position: relative;
		margin: 0;
		padding: .5rem 0;
		box-shadow: none !important;

		&:hover {
			box-shadow: none;
		}
	}

	.product-title {
		margin: 0;
		color: #696969;
		font-size: 1.1rem;
		font-weight: 400;
		line-height: 1.35;
		text-transform: uppercase;
	}

	.btn-remove {
		display: -ms-flexbox;
		display: flex;
		position: absolute;
		top: 50%;
		right: 0;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: 2.3rem;
		height: 2.3rem;
		margin-top: -1.2rem;
		padding: .5rem 0;
		color: #777;
		font-size: 1.3rem;
		line-height: 1;
		text-align: center;
		overflow: hidden;
	}
}

.compare-actions {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 2rem;

	.action-link {
		display: inline-block;
		color: #777;
		font-size: 1.1rem;
		text-transform: uppercase;
	}

	.btn {
		min-width: 110px;
		margin-left: auto;
		padding: .9rem 1rem;
		border: 0;
		border-radius: .2rem;
		color: #fff;
		font-size: 1.2rem;
		font-weight: 400;
		letter-spacing: .025rem;
		text-align: center;
		text-transform: uppercase;
	}
}

.cart-dropdown {
	.dropdown-toggle {
		display: block;
		position: relative;
		padding-right: 1.5rem;

		.cart-count {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 1.6rem;
			height: 1.6rem;
			position: absolute;
			padding-top: 2px;
			right: 0;
			line-height: 1;
			top: .3rem;
			border-radius: 50%;
			padding-bottom: 1px;
			font-size: 1rem;
			font-weight: 600;
			background-color: #ed7d65;
			color: #fff;
			box-shadow: 0 7px 8px rgba(0, 0, 0, .05);
		}

		.minicart-icon {
			width: 25px;
			height: 20px;
			border: 2px solid #222529;
			border-radius: 0 0 5px 5px;
			position: relative;
			opacity: .9;
			top: 1.2rem;
			display: block;
		}

		.minicart-icon:before {
			content: '';
			position: absolute;
			border: 2px solid;
			border-color: inherit;
			border-bottom: none;
			border-radius: 10px 10px 0 0;
			left: 50%;
			top: -8px;
			margin-left: -7.5px;
			width: 15px;
			height: 11px;
		}

		&:after {
			border: none;
		}
	}

	.dropdown-menu {
		right: 0;
		left: auto;
		padding-top: 8px;
	}

	.dropdownmenu-wrapper {
		padding: 1.6rem 1.9rem;

		&:before {
			right: 28px;
			left: auto;
		}

		&:after {
			right: 29px;
			left: auto;
		}
	}

	.product {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		margin: 0 !important;
		padding: 1.6rem 1rem;
		border-bottom: 1px solid #e6ebee;
		box-shadow: none !important;
	}

	.product-image-container {
		position: relative;
		max-width: 80px;
		margin: 0;
		margin-left: auto;
		border: 1px solid #ededed;

		a:after {
			display: none;
		}
	}

	.product-title {
		margin-bottom: .2rem;
		color: #696969;
		font-size: 1.3rem;
		font-weight: 600;
		text-align: left;
	}

	.product-details {
		color: #696969;
		font-size: 1.3rem;
		font-weight: 600;
		text-align: left;
	}

	.btn-remove {
		display: flex;
		position: absolute;
		top: -1rem;
		right: -1rem;
		align-items: center;
		justify-content: center;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		background-color: #fff;
		color: #474747;
		font-size: 1.1rem;
		line-height: 1;
		box-shadow: 0 2px 5px rgba(0, 0, 0, .2);

		&:hover,
		&:focus {
			color: $primary-color;
			box-shadow: 0 3px 7px rgba(0, 0, 0, .3);
		}
	}
}

.dropdown-cart-header,
.dropdown-cart-total {
	padding: .7rem 1rem;
	display: flex;
	align-items: flex-end;
	font-size: 1.3rem;
	font-weight: 700;
	text-transform: uppercase;
	color: #474747;
}

.dropdown-cart-total {
	margin-bottom: 1.1rem;

	.cart-total-price {
		margin-left: auto;
		font-size: 1.5rem;
	}
}

.dropdown-cart-header {
	border-bottom: 1px solid #e6ebee;
	margin-bottom: 0;
	padding-top: 0;

	a {
		color: inherit;
		margin-left: auto;
		font-size: 1.2rem;
	}
}

.dropdown-cart-action {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: .2rem 1rem 1.5rem;

	.btn {
		font-weight: 400;
		font-size: 1.2rem;
		letter-spacing: .05rem;
		text-transform: uppercase;
		color: #fff;
		border-color: $secondary-color;
		background-color: $secondary-color;
		border-radius: .2rem;
		min-width: 110px;
		padding: 1.25rem 1rem;
		font-family: $font-family;

		&:hover,
		&:focus {
			color: #fff;
			border-color: lighten($secondary-color, 5%);
			background-color: lighten($secondary-color, 5%);
		}
	}
}

// Header Search
.header-search {
	position: relative;
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 0;
	line-height: 1;

	.header-search-wrapper {
		display: none;
		align-items: center;
		overflow: visible;
		border: none;
		top: 4.3rem;
		position: absolute;
		right: 430%;
		width: 100%;
		z-index: 999;
		@include clearfix;

		&.show {
			display: flex;
		}

		&:before {
			display: block;
			position: absolute;
			top: -20px;
			right: -280px;
			width: 20px;
			height: 20px;
			border: 10px solid transparent;
			border-bottom-color: #dbe0e2;
			content: '';
		}
	}

	form {
		margin: 0;
	}

	.select-custom {
		flex: 0 0 152px;
		margin: 0;
		background: #fff;

		&:after {
			right: 2.8rem;
			color: #8d8d8d;
			font-size: 1.4rem;
		}
	}

	.form-control,
	select {
		height: 50px;
		font: 400 1.3rem/1.5 $font-family;
		background: #fff;
		margin: 0;
	}

	.form-control {
		font-size: 1.4rem;
		flex: 1 1 auto;
		width: 200px;
		height: 50px;
		min-width: 17rem;
		margin: 0;
		padding: 4px 22px;
		border: 2px solid #e7e7e7;
		border-right: none;
		border-radius: 30px 0 0 30px;
		color: #8d8d8d;
		line-height: 20px;
		box-shadow: none;

		&::-webkit-input-placeholder {
			color: #8d8d8d;
		}

		&::-moz-placeholder {
			color: #8d8d8d;
		}

		&:-ms-input-placeholder {
			color: #8d8d8d;
		}

		&::-ms-input-placeholder {
			color: #8d8d8d;
		}

		&::placeholder {
			color: #8d8d8d;
		}

		&:focus {
			border-color: #e7e7e7;
		}
	}

	select {
		width: 100%;
		border: 2px solid #e7e7e7;
		border-right: none;
		border-left: 1px solid #e7e7e7;
		line-height: 36px;
		color: #8d8d8d;
		padding: 1px 20px 1px 15px;
		padding-left: 25px;
		border-radius: 0;
		-moz-appearance: none;
		-webkit-appearance: none;

		&:focus {
			outline: none;
		}
	}

	.btn {
		flex: 0 0 65px;
		height: 50px;
		min-width: 0;
		color: #fff;
		font-weight: 400;
		font-size: 2rem;
		line-height: 1;
		border: 2px solid #e7e7e7;
		border-left: 2px solid #e7e7e7;
		padding: 0 3px 0 0;
		margin: 0;
		cursor: pointer;
		border-radius: 0 20px 20px 0;
		background-color: $primary-color-dark;

		i {
			&:before {
				margin: 0;
			}
		}

		&:hover,
		&:focus {
			color: $primary-color;
		}

	}

	.search-toggle {
		display: inline-block;
		font-size: 1.3rem;
		line-height: 1;
		min-width: 2.5rem;
		padding: 1rem 0;
		font-weight: 700;
		color: $secondary-color;
		text-align: center;

		i {
			display: inline-block;
			margin-bottom: 2px;
			font-size: 1.8rem;
			transition: none;
		}

		&:hover,
		&:hover i {
			color: $primary-color;
		}
	}
}

.header-contact {
	position: relative;
	color: $secondary-color;
	letter-spacing: 0;
	font-size: 1.8rem;
	line-height: 1.4;
	padding-right: 3.6rem;
	margin-right: 3.2rem;

	&:after {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;
		display: block;
		width: 2px;
		height: 43px;
		margin-top: -21.5px;
		background-color: #efefef;
	}

	span {
		display: block;
		color: #787d7f;
		font-size: 1.1rem;
		font-weight: 600;
		text-transform: uppercase;
	}

	a {
		color: inherit;

		&:hover,
		&:focus {
			color: $primary-color;
			text-decoration: none;
		}
	}
}

.header-bottom {
	background: none;
	// box-shadow: 0 28px 57px rgba(0, 0, 0, 0.06);

	.container,
	.container-fluid {
		position: relative;
	}

	.cart-dropdown {
		display: none;
	}

	.logo {
		margin-right: 2.5rem;

		img {
			width: auto;
			max-height: 57px;
		}
	}

	.porto-icon {
		display: none;
	}

	.cart-dropdown {
		order: 2;
		margin-left: auto;

		.dropdown-toggle {
			padding-right: 1.6rem;

			&:before {
				font-size: 2.6rem;
			}

			.cart-count {
				right: .9rem;
			}
		}

		.dropdownmenu-wrapper:before {
			right: 15px;
		}

		.dropdownmenu-wrapper:after {
			right: 16px;
		}
	}

	.select-custom:after {
		top: 48%;
	}

	.header-search .select-custom:after {
		right: 1.8rem;
	}
}

.mobile-menu-toggler {
	border: 0;
	background: transparent;
	color: $secondary-color;
	padding: 1rem .5rem;
	font-size: 3rem;
	line-height: 1;
	display: none;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-right: 2rem;
	cursor: pointer;

	i {
		&:before {
			margin: 0;
		}
	}
}

@include mq(sm) {
	.welcome-msg {
		display: block;
	}
}

@include mq(md) {
	.welcome-msg {
		margin-right: 3rem;
	}
}

@include mq(lg) {
	.dropdown-expanded {
		> a {
			display: none;
		}

		.header-menu {
			position: static;
			top: auto;
			right: auto;
			left: auto;
			padding: 0;
			border: 0;
			background-color: transparent;
			box-shadow: none;
			opacity: 1;
			visibility: visible;

			ul {
				display: -ms-flexbox;
				display: flex;
				-ms-flex-align: center;
				align-items: center;

				li {
					& + li {
						margin-left: 3rem;
					}
				}

				li + li:before {
					position: absolute;
					top: 50%;
					left: -14px;
					height: 11px;
					margin-top: -5.5px;
					border-right: 1px solid;
					content: '';
					opacity: .8;
				}

				a {
					padding: 0;
				}
			}

			a {
				color: inherit;

				&:hover,
				&:focus {
					background-color: transparent;
					color: #999;
					text-decoration: underline;
				}
			}
		}
	}
}

@include mq(lg, max) {
	.header-search {
		margin-left: 5px;

		.header-search-wrapper {
			position: absolute;
			top: 100%;
			right: -80px;
			width: 450px;
			margin-top: .3rem;
			border-width: 5px;
			border-radius: 24px;
			box-shadow: 0 4px 12px rgba(0, 0, 0, .075);
			z-index: 99;
			border-color: #dbe0e2;

			&:before {
				display: block;
				position: absolute;
				top: -25px;
				right: 77px;
				width: 20px;
				height: 20px;
				border: 10px solid transparent;
				border-bottom-color: #dbe0e2;
				content: '';
			}
		}
	}

	.cart-dropdown {
		.dropdown-toggle:before {
			font-size: 2.6rem;
		}
	}

	.header-middle {
		.header-right {
			margin-left: 1rem;
		}

		.header-left {
			display: none;
		}
	}

	.header-center {
		margin-left: 0;
		margin-right: auto;
	}

	.header-contact {
		margin-right: 1.8rem;
		padding-right: 2rem;
	}

	.header-bottom {
		display: none;
	}

	.mobile-menu-toggler {
		display: flex;
	}
}

@include mq(md, max) {
	.header-contact {
		display: none;
	}

	.header-search {
		.header-search-wrapper {
			width: 380px;
		}

		.select-custom {
			flex: 0 0 132px;
		}

		.btn {
			flex: 0 0 40px;
		}
	}

	.cart-dropdown {
		.dropdown-toggle {

			&:before {
				font-size: 2.3rem;
			}
		}
	}

	.header-middle .header-right {
		margin-left: .7rem;
	}
}

@include mq(sm, max) {
	.header-userinfo {
		display: none;
	}
	.header-user i {
		border: none;
	}
	.header-user {
		margin-right: 0;
		margin-bottom: 2px;
	}
}

@include mq(xs, max) {
	.header-search .header-search-wrapper {
		width: 320px;
	}

	.compare-dropdown {
		display: none;
	}
}

@media (max-width: 360px) {
	.header-search {
		.header-search-wrapper {
			width: 240px;
		}

		.select-custom {
			display: none;
		}
	}

	.cart-dropdown,
	.compare-dropdown {
		.dropdown-menu {
			width: 260px;
		}

		.dropdownmenu-wrapper {
			padding: 1rem 1.4rem;
		}
	}

	.dropdown-cart-action .btn {
		min-width: 100px;
	}
}
// .main-nav li:nth-child(1),
// .main-nav li:nth-child(3), 
// .main-nav li:nth-child(4), 
// .main-nav li:nth-child(6), 
// .main-nav li:nth-child(7) 
// {
//     display: none;
// }
// main.home.main {
//     margin-top: 46px;
// }
// .home section.section-primary {
//     height: 800px!important;
// }
.midlogo--modifier{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	span{
		font-size: 34px;
		font-weight: 700;
		color: white;
		text-transform: uppercase;
		text-align: center;
	}
	p{
		font-size: 16px;
		font-weight: 400;
		color: white;
		text-transform: uppercase;
	}
}
.row--modifier{
	width: 100%;
}
.main--menu--modifier{
	justify-content: end;
}
.acount--menu{
	float: right;
	margin: 0px;
	li{
		float: left;
		list-style: none;
		font-size: 11px;
		font-weight: 400;
		color: $color-black;
		text-transform: uppercase;
		padding-left: 12px;
		position: relative;
		.icon-search-1 {
			font-size: 24px!important;
			margin-top: -8px!important;
			float: right;
		}
		.form-control{
			border: 0px;
		}
		&:hover .inactive {
			display: block!important;
			position: absolute;
			width: 300px;
			right: 0;
			background: white;
			height: 52px;
			border: 1px solid #e6e6e6;
			border-radius: 4px;
		}
		a{
			color: $color-black;
			&:hover{
				color: $color-black;
			}
		}
	}
	.inactive{
		display: none;
	}
}
.acount--menu li:hover .inactive {
    display: block!important;
    position: absolute;
	z-index: 9;
	.form-control{
		height: 4.5rem;
	}
}
@media screen and (min-width:992px) and (max-width:10000px) {
	html body .header-top.section-dark {
		background-color: #000!important;
		color: #fff !important;
	}
}
@media screen and (min-width:991px) and (max-width:10000px) {
	// .icon-bag{
	// 	display: none;
	// }
	.inactive .icon-search-1 {
		display: block!important;
	}
	.acount--menu li .icon-search-1{
		display: none;
	}
	.header .menu li{
		line-height: 1px;
		margin-top: 0px;
		margin-right: 6px;
	}
	// .header .fixed .menu li{
	// 	line-height: auto;
	// 	margin-top: 21px;
	// }
	.header .menu > li{
		margin-bottom: 24px;
	}
	.header .fixed .menu > li{
		margin-top: 14px;
		margin-bottom: 16px
	}
	.main-nav > ul{
		max-height: 54px;
	}


}
@media screen and (min-width:768px) and (max-width:991px) {
	.header .container{
		margin: auto!important;
	}
	.header-middle{
		margin-left: 0px!important;
	
	}
}
@media screen and (min-width:320px) and (max-width:991px) {
	html body .headerFixed {
		background: none!important;
		box-shadow: none!important
	}
	.header-top .container {
		display: inherit !important;
		max-width: 99%;
		margin: 0px;
	}
	.accountCartSearchWrap {
		justify-content: flex-end!important;
		margin-top: 10px!important;
	}
	html body .header-top.section-dark h4, html body .header-top.section-dark .h4 {
		color: #fff !important;
		background: #000!important;
		width: calc(100% + 22px) !important;
		margin-left: -10px!important;
		padding: 10px 0px!important;
		margin-top: -14px!important;
	}
	html body .header-top.section-dark h4 a, html body .header-top.section-dark .h4 a {
		color: #fff!important;
	}
	html body .header-top.section-dark {
		background-color: #fff !important;
		color: #000 !important;
	}
	html body .header-top.section-dark i,
	html body .header-top.section-dark a {
		color: #000 !important;
	}
	.header .logo--c1 {
		display: none!important;
	}
	img.header-nav-top-icon {
		display: none;
	}
	.simple-search img.header-nav-top-icon {
		display: block;
	}
	.header-nav-feature {
		float: left;
	}
	body .header__logged-out {
		padding-right: 6px;
		padding-top: 5px!important;
	}
	.home-slide h1{
		font-size: 40px!important;
		text-align: left!important;
	 }
	.header{
		position: relative;
		top: auto;
			.headerlogoMobile{
				display: block;
				width: 120px;
				padding: 12px;
				float: left;
			}
			.header-middle{
				display: block;
				float: right;
				position: relative;
			}
	}
	.header-top{
		text-align: center;
		display: block;
		margin-top: 0px!important;
		margin-bottom: 12px;
		padding: 14px 0px!important;
		float: left;
		width: 100%;
	}
	.header .logo {
		display: none;
	}
	main.home.main {
		margin-top: 0px!important;
		clear: both;
	}
	.acount--menu {
		position: absolute !important;
		right: 60px !important;
		bottom: -45px !important;
		top: auto !important;
		li{
			float: right;
			&:hover .inactive {
				width: 250px;
				height: 42px;
			}
			.simple-search{
				input[type="search"]{
					padding-right: 0;
					height: 40px;
					font-size: 1.3rem;
				}
				.btn{
					padding-right: 16px;
					i{
						font-size: 18px !important;
						margin-top: -4px !important;
					}
				}
			}
		}
		li:nth-child(2) {
			margin-right: 15px;
		}
	}
	span.cart-info{
		display: none;
	}
	.header-top i{
		color: #000 !important;
	}
	.acount--menu li .icon-search-1 {
		font-size: 2.8rem !important;
		margin-top: 0 !important;
		float: right;
		margin-right: -11px;
	}
	.search--text{
		display: none;
	}
	.mobile-menu-toggler{
		color: #000;
		margin-right: 0px;
	}
	.sticky-wrapper{
		height: auto !important;
	}


}
@media screen and (max-width:767px) {
	// html body .header-top.section-dark .headerlogoMobile img {
	// 	display: none;
	// }
	// html body .header-top.section-dark .headerlogoMobile a:before {
	// 	background: url(https://athancomau.coredna.site/files/images/components/logo-middle.png);
	// 	width: 100px;
	// 	height: 40px;
	// 	float: left;
	// 	content: "";
	// 	background-size: contain;
	// 	background-repeat: no-repeat;
	// }
	span.cart-info{
		display: none;
	}
	.home-slide h1{
		font-size: 40px!important;
		text-align: left!important;
	 }
	 .slider-section.athan-slider-section .home-slide .home-slide-content p{
		// text-align: left!important;
	 }
}
.fixed .midlogo--modifier img {
    margin-top: 13.1px;
}
html body .btn-light {
	color: #000!important;
  }
::selection {
    color: #FFF!important;
    background: #000!important;
}
html body .header-top {
    background-color: #fff!important;
    color: #000!important;
}
.home-slide .banner-subtitle,
.home-slide .banner-title{
	text-align: left!important;
}
.searchCartWrap form{
	margin: 0px 10px!important;
	padding: 0px!important;
}
.accountCartSearchWrap {
    display: flex;
}
.searchCartWrap {
    display: flex;
}
#header .header-nav-features{
	padding-right: 0px!important;
}
.header__logged-out {
    padding-top: 8px!important;
}
#header .header-nav-features .header-nav-cart {
    font-size: 2rem!important;
    top: 0px !important;
}
#header .header-nav-features .header-nav-top-icon, #header .header-nav-features .header-nav-top-icon-img {
    top: 2px!important;
}
#header .header-nav-features {
    padding-left: 0!important;
    margin-left: 0!important;
}
body html ::-webkit-scrollbar-thumb {
    background: #000!important;
}
.simple-search .form-control{
	border-radius: 1.3rem!important;
    padding: 0.375rem 0.75rem!important;
    line-height: 1.85!important;
	height: auto!important;
}
.searchCartWrap .input-group .btn {
    position: absolute!important;
    right: 0!important;
}
.searchCartWrap .header-nav-features {
    position: relative;
}
.searchCartWrap form:before {
    background: rgba(0, 0, 0, 0.03);
    content: "";
    width: 1px;
    height: 16px;
    position: absolute;
    top: 5px;
    left: 0px;
}
.searchCartWrap form:after {
    background: rgba(0, 0, 0, 0.03);
    content: "";
    width: 1px;
    height: 16px;
    position: absolute;
    top: 5px;
    right: 0px;
}
.loader .dot{
	background-color: #a9a8a9!important;
  }
  @font-face {
	font-family: 'MessinaSansRegular';
	src:url('../fonts/MessinaSansWeb-Regular.woff2') format('woff2'),
	url('../fonts/MessinaSansWeb-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
body{
	font-family: 'MessinaSansRegular'!important;
}
.header-top a:hover {
    color: #605d5d!important;
}
a:active {
    color: #605d5d!important;
}
a:focus {
    color: #605d5d!important;
}
.header-top a{
	font-family: 'MessinaSansRegular'!important;
}
.headerFixed {
    background: #FBF7F3;
    box-shadow: 0px 0px 8px 3px #f5eae0;
}
.headerFixed .header-bottom{
	padding-bottom: 20px;
}

html body{
	.header-top{
&.section-beige{
	background-color: #FBF7F3 !important;
}
&.section-dark{
	background-color: #000000;
	color: #fff !important;
	h4{
		color: #fff !important;
		span{
			color: #fff !important;
		}
	}
	a{
		color: #fff;
		&:hover {
			color: #fff;
		}
	}
	i{
		color: #fff;
	}
	#headerTopCartDropdown{
		p{
			color: #000 !important;
		}
	}

}
	}
}
.simple-search {
	.btn {
		padding: 0.7rem 0.933rem!important;
	}
}
.header__logged-in div span:nth-child(1){
    margin-right: -3px!important;
}
::-webkit-input-placeholder{
	color: #ced4da!important;
}
.simple-search .form-control{
	border: 1px solid #ced4da!important;
}
.menu>li>a{
	font-family: 'MessinaSansRegular'!important;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li {
    padding: 15px 0 27px 0px!important;
}
.unboxing22{
	color: red;
}

@media screen and (min-width: 992px) and (max-width: 1599px) {

	.menu ul ul {
		left: auto !important;
		right: 100%;
	}

}