// Cart
.cart-table-container {
	margin-bottom: 2.5rem;
}

.table.table-cart {
	tr {
		th,
		td {
			vertical-align: middle;

			&:not(.product-col) {
				text-align: center;
			}
		}

		th {
			padding: 2.4rem 1.5rem 1.2rem;
			border: 0;
			font-weight: 600;
		}

		td {
			padding: 2rem 1.5rem;
			border-top: 1px solid #ccc;

			&.product-col {
				padding: 2rem .8rem 1.8rem 0;
			}
		}

		&.product-action-row {
			td {
				padding: 0 0 2.2rem;
				border: 0;
			}
		}
	}

	.product-title {
		margin-bottom: 0;
	}

	tfoot {
		td {
			padding: 2rem 0 1rem;
		}

		.btn {
			padding: .85rem 2rem;
			font-family: $font-family;
			letter-spacing: 0;
			text-transform: initial;

			& + .btn {
				margin-left: 1rem;
			}
		}
	}

	.bootstrap-touchspin.input-group {
		margin-right: auto;
		margin-left: auto;
	}
}

.btn-remove {
	&:before {
		font-size: 1.8rem;
		font-weight: 600;
		content: 'x';
	}
}

.btn-remove,
.btn-edit,
.btn-move {
	color: #3f4658;

	&:hover,
	&:focus {
		color: $primary-color;
		text-decoration: none;
	}
}

.btn-edit {
	margin-right: 1rem;
	font-size: 1.3rem;
}

.btn-move {
	font-size: 1.3rem;
	line-height: 2.5rem;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.qty-col {
	min-width: 98px;
}

.product-col {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;

	tbody & {
		font-size: 0;
	}

	.product-image-container {
		-ms-flex: 0 0 180px;
		flex: 0 0 180px;
		max-width: 180px;
		margin-right: 1.8rem;
		margin-bottom: 0;
		border: 1px solid #ccc;
		vertical-align: middle;
	}

	.product-title {
		display: inline-block;
		vertical-align: middle;
	}
}

.cart-discount {
	margin-bottom: 4rem;

	h4 {
		margin-bottom: 1.2rem;
		font-size: 1.6rem;
		font-weight: 400;
	}

	form {
		max-width: 420px;
	}
}

.cart-summary {
	margin-bottom: 2.5rem;
	padding: 1.4rem 1.8rem 2.2rem;
	border: 1px solid #ddd;
	background: #fbfbfb;

	h3 {
		font-weight: 400;
		font-size: 2.3rem;
		margin-bottom: 1.3rem;
	}

	h4 {
		font-size: 1.5rem;
		font-weight: 600;
		border-top: 1px solid #cccccc;
		margin-bottom: 0;

		a {
			position: relative;
			display: block;
			color: inherit;
			padding: 1rem 2rem 1rem 0;


			&:after {
				font-family: 'porto';
				content: '\e81b';
				position: absolute;
				display: inline-block;
				right: .4rem;
				top: 50%;
				font-size: 1.4rem;
				transform: translateY(-50%);
			}

			&.collapsed:after {
				content: '\e81c';
			}
		}
	}
}

#total-estimate-section {
	form {
		margin-bottom: 0;
		padding: .7rem 0 2.2rem;
	}
}

.table.table-totals {
	margin-bottom: 2rem;
	border-top: 1px solid #ccc;

	tbody {
		tr:first-child {
			td {
				padding-top: 1.6rem;
			}
		}
	}

	tr {
		border: 0;

		th,
		td {
			padding: .6rem 0;
			border: 0;

			&:last-child {
				text-align: right;
			}
		}
	}

	tfoot {
		border-top: 1px solid #ccc;
		font-size: 1.8rem;
		font-weight: 400;

		tr td {
			padding-top: 1.3rem;
			padding-bottom: 1.6rem;

			&:last-child {
				color: $headings-text;
			}
		}
	}
}

.checkout-methods {
	.btn {
		letter-spacing: 0;

		+ .btn {
			margin-top: 2.2rem;
		}
	}
}

@include mq(md, max) {
	.table.table-cart {
		&,
		tbody,
		tfoot {
			display: block;
		}

		thead {
			display: none;
		}

		tr {
			td {
				padding: .5rem 1rem;
				border-top: 0;

				&.product-col {
					padding-bottom: .5rem;
				}
			}
		}

		.product-row {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-direction: column;
			flex-direction: column;
			border-top: 1px solid #ccc;

			&:first-of-type {
				border-top: 0;
			}
		}

		.product-action-row {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;

			td {
				width: 100%;
			}

			.float-right {
				margin-top: -4px;
				margin-left: auto;
			}
		}

		.product-col {
			-ms-flex-direction: column;
			flex-direction: column;
			-ms-flex-pack: center;
			justify-content: center;
			text-align: center;

			.product-image-container {
				-ms-flex: 0 0 auto;
				flex: 0 0 auto;
				margin-right: 0;
				margin-bottom: 1rem;
			}
		}

		tfoot {
			border-top: 1px solid #ccc;

			tr {
				display: block;
				width: 100%;

				td {
					display: -ms-flexbox;
					display: flex;
					-ms-flex-direction: column;
					flex-direction: column;
					-ms-flex-align: start;
					align-items: flex-start;
					padding: 1rem 0;
				}
			}

			.btn {
				margin-top: .5rem;
				padding-right: 1rem;
				padding-left: 1rem;
			}

			.float-left {
				display: none;
			}

			.float-right {
				margin-left: auto;
			}
		}
	}
}