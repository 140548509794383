@font-face {
	font-family: 'porto';
	src: url('../fonts/porto.eot?64334846');
	src: url('../fonts/porto.eot?64334846#iefix') format('embedded-opentype'),
	url('../fonts/porto.woff2?64334846') format('woff2'),
	url('../fonts/porto.woff?64334846') format('woff'),
	url('../fonts/porto.ttf?64334846') format('truetype'),
	url('../fonts/porto.svg?64334846#porto') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-']:before, [class*=' icon-']:before {
	display: inline-block;
	width: 1em;
	margin-right: .2em;
	margin-left: .2em;
	font-family: 'porto';
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1em;
	text-align: center;
	text-decoration: inherit;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	speak: never;
}

.icon-cancel:before {
	content: '\e800';
}

.icon-spin2:before {
	content: '\e801';
}

.icon-spin3:before {
	content: '\e802';
}

.icon-spin4:before {
	content: '\e803';
}

.icon-spin5:before {
	content: '\e804';
}

.icon-spin6:before {
	content: '\e805';
}

.icon-firefox:before {
	content: '\e806';
}

.icon-chrome:before {
	content: '\e807';
}

.icon-opera:before {
	content: '\e808';
}

.icon-ie:before {
	content: '\e809';
}

.icon-phone:before {
	content: '\e80a';
}

.icon-down-dir:before {
	content: '\e80b';
}

.icon-cart:before {
	content: '\e80c';
}

.icon-up-dir:before {
	content: '\e80d';
}

.icon-mode-grid:before {
	content: '\e80e';
}

.icon-mode-list:before {
	content: '\e80f';
}

.icon-compare:before {
	content: '\e810';
}

.icon-wishlist:before {
	content: '\e811';
}

.icon-search:before {
	content: '\e812';
}

.icon-left-dir:before {
	content: '\e813';
}

.icon-right-dir:before {
	content: '\e814';
}

.icon-down-open:before {
	content: '\e815';
}

.icon-left-open:before {
	content: '\e816';
}

.icon-right-open:before {
	content: '\e817';
}

.icon-up-open:before {
	content: '\e818';
}

.icon-angle-left:before {
	content: '\e819';
}

.icon-angle-right:before {
	content: '\e81a';
}

.icon-angle-up:before {
	content: '\e81b';
}

.icon-angle-down:before {
	content: '\e81c';
}

.icon-down:before {
	content: '\e81d';
}

.icon-left:before {
	content: '\e81e';
}

.icon-right:before {
	content: '\e81f';
}

.icon-up:before {
	content: '\e820';
}

.icon-angle-double-left:before {
	content: '\e821';
}

.icon-angle-double-right:before {
	content: '\e822';
}

.icon-angle-double-up:before {
	content: '\e823';
}

.icon-angle-double-down:before {
	content: '\e824';
}

.icon-mail:before {
	content: '\e825';
}

.icon-location:before {
	content: '\e826';
}

.icon-skype:before {
	content: '\e827';
}

.icon-right-open-big:before {
	content: '\e828';
}

.icon-left-open-big:before {
	content: '\e829';
}

.icon-down-open-big:before {
	content: '\e82a';
}

.icon-up-open-big:before {
	content: '\e82b';
}

.icon-retweet:before {
	content: '\e82c';
}

.icon-user:before {
	content: '\e82d';
}

.icon-mail-alt:before {
	content: '\e82e';
}

.icon-fax:before {
	content: '\e82f';
}

.icon-lock:before {
	content: '\e830';
}

.icon-company:before {
	content: '\e831';
}

.icon-city:before {
	content: '\e832';
}

.icon-post:before {
	content: '\e833';
}

.icon-country:before {
	content: '\e834';
}

.icon-calendar:before {
	content: '\e835';
}

.icon-doc:before {
	content: '\e836';
}

.icon-mobile:before {
	content: '\e837';
}

.icon-clock:before {
	content: '\e838';
}

.icon-chat:before {
	content: '\e839';
}

.icon-tag:before {
	content: '\e83a';
}

.icon-folder:before {
	content: '\e83b';
}

.icon-folder-open:before {
	content: '\e83c';
}

.icon-forward:before {
	content: '\e83d';
}

.icon-reply:before {
	content: '\e83e';
}

.icon-cog:before {
	content: '\e83f';
}

.icon-cog-alt:before {
	content: '\e840';
}

.icon-wrench:before {
	content: '\e841';
}

.icon-quote-left:before {
	content: '\e842';
}

.icon-quote-right:before {
	content: '\e843';
}

.icon-gift:before {
	content: '\e844';
}

.icon-dollar:before {
	content: '\e845';
}

.icon-euro:before {
	content: '\e846';
}

.icon-pound:before {
	content: '\e847';
}

.icon-rupee:before {
	content: '\e848';
}

.icon-yen:before {
	content: '\e849';
}

.icon-rouble:before {
	content: '\e84a';
}

.icon-try:before {
	content: '\e84b';
}

.icon-won:before {
	content: '\e84c';
}

.icon-bitcoin:before {
	content: '\e84d';
}

.icon-ok:before {
	content: '\e84e';
}

.icon-chevron-left:before {
	content: '\e84f';
}

.icon-chevron-right:before {
	content: '\e850';
}

.icon-export:before {
	content: '\e851';
}

.icon-star:before {
	content: '\e852';
}

.icon-star-empty:before {
	content: '\e853';
}

.icon-plus-squared:before {
	content: '\e854';
}

.icon-minus-squared:before {
	content: '\e855';
}

.icon-plus-squared-alt:before {
	content: '\e856';
}

.icon-minus-squared-alt:before {
	content: '\e857';
}

.icon-truck:before {
	content: '\e858';
}

.icon-lifebuoy:before {
	content: '\e859';
}

.icon-pencil:before {
	content: '\e85a';
}

.icon-users:before {
	content: '\e85b';
}

.icon-video:before {
	content: '\e85c';
}

.icon-menu:before {
	content: '\e85d';
}

.icon-desktop:before {
	content: '\e85e';
}

.icon-doc-inv:before {
	content: '\e85f';
}

.icon-circle:before {
	content: '\e860';
}

.icon-circle-empty:before {
	content: '\e861';
}

.icon-circle-thin:before {
	content: '\e862';
}

.icon-mini-cart:before {
	content: '\e863';
}

.icon-paper-plane:before {
	content: '\e864';
}

.icon-attention-alt:before {
	content: '\e865';
}

.icon-info:before {
	content: '\e866';
}

.icon-compare-link:before {
	content: '\e867';
}

.icon-cat-default:before {
	content: '\e868';
}

.icon-cat-computer:before {
	content: '\e869';
}

.icon-cat-couch:before {
	content: '\e86a';
}

.icon-cat-garden:before {
	content: '\e86b';
}

.icon-cat-gift:before {
	content: '\e86c';
}

.icon-cat-shirt:before {
	content: '\e86d';
}

.icon-cat-sport:before {
	content: '\e86e';
}

.icon-cat-toys:before {
	content: '\e86f';
}

.icon-tag-line:before {
	content: '\e870';
}

.icon-bag:before {
	content: '\e87f';
}

.icon-search-1:before {
	content: '\e872';
}

.icon-plus:before {
	content: '\e873';
}

.icon-minus:before {
	content: '\e874';
}

.icon-search-2:before {
	content: '\e875';
}

.icon-bag-1:before {
	content: '\e876';
}

.icon-online-support:before {
	content: '\e877';
}

.icon-shopping-bag:before {
	content: '\e878';
}

.icon-us-dollar:before {
	content: '\e879';
}

.icon-shipped:before {
	content: '\e87a';
}

.icon-list:before {
	content: '\e87b';
}

.icon-money:before {
	content: '\e87c';
}

.icon-shipping:before {
	content: '\e87d';
}

.icon-support:before {
	content: '\e87e';
}

.icon-bag-2:before {
	content: '\e87f';
}

.icon-grid:before {
	content: '\e880';
}

.icon-bag-3:before {
	content: '\e881';
}

.icon-direction:before {
	content: '\e882';
}

.icon-home:before {
	content: '\e883';
}

.icon-magnifier:before {
	content: '\e884';
}

.icon-magnifier-add:before {
	content: '\e885';
}

.icon-magnifier-remove:before {
	content: '\e886';
}

.icon-phone-1:before {
	content: '\e887';
}

.icon-clock-1:before {
	content: '\e888';
}

.icon-heart:before {
	content: '\e889';
}

.icon-heart-1:before {
	content: '\e88a';
}

.icon-earphones-alt:before {
	content: '\e88b';
}

.icon-credit-card:before {
	content: '\e88c';
}

.icon-action-undo:before {
	content: '\e88d';
}

.icon-envolope:before {
	content: '\e88e';
}

.icon-chart:before {
	content: '\e88f';
}

.icon-down-1:before {
	content: '\e890';
}

.icon-up-1:before {
	content: '\e891';
}

.icon-eye:before {
	content: '\e892';
}

.icon-spin1:before {
	content: '\e893';
}

.icon-twitter:before {
	content: '\f099';
}

.icon-facebook:before {
	content: '\f09a';
}

.icon-gplus:before {
	content: '\f0d5';
}

.icon-linkedin:before {
	content: '\f0e1';
}

.icon-spinner:before {
	content: '\f110';
}

.icon-instagram:before {
	content: '\f16d';
}

.icon-sliders:before {
	content: '\f1de';
}

.icon-question-circle-o:before {
	content: '\f29c';
}

.icon-check-empty:before {
	content: '\f87a';
}

.icon-check:before {
	content: '\f87b';
}

.icon-docs-inv:before {
	content: '\e895';
}

.icon-briefcase:before {
	content: '\e896';
}

.icon-user-2:before {
	content: '\e91a';
}

.icon-search-3:before {
	content: '\e918';
}