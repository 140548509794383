.single-row {
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-ms-flex-align: center;
	align-items: center;
	height: 700px;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	color: #7b858a;
	font-size: 1.4rem;
	letter-spacing: .005em;
	line-height: 1.92;

	.single-row-entire,
	.single-row-bg {
		position: absolute;
		top: 0;
		bottom: 0;
		background-color: #ccc;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

	.single-row-entire {
		width: 100%;
	}

	.single-row-bg {
		left: 50%;
		width: 50%;
	}

	&.single-row-reverse {
		.single-row-bg {
			left: 0;
		}
	}

	h5 {
		//margin-bottom: 1.1rem;
		//margin-left: .6rem;
		color: #7b858a;
		font-size: 2rem;
		font-weight: 600;
		letter-spacing: -.01em;
	}

	h2 {
		//margin-bottom: 2.8rem;
		font-size: 4.5rem;
		font-weight: 600;
		letter-spacing: -.01em;
	}

	p {
		//margin-bottom: 0;
	}

	ul,
	ol {
		//margin-bottom: 0;

		li {
			//margin-bottom: .4rem;

			i {
				margin-right: 2rem;
				color: #21293c;
				font-size: 1.5rem;

				&:before {
					margin: 0;
				}
			}
		}
	}

	&.single-row-height-sm {
		min-height: $row-height-sm;
		height: auto;
	}

	&.single-row-height-md {
		height: $row-height-md;
	}

	&.single-row-height-lg {
		height: $row-height-lg;
	}
	&.single-row-height-auto {
		height: auto;
	}

	.dark-colour {
		color: $primary-color-dark;
	}
}

@media all and (max-width:767px){

	.single-row {
		flex-direction: column;
    	padding: 3rem 0 3rem;
		height: auto !important;
		.single-row-bg {
			position: static;
			width: 100%;
			height: 300px;
			margin-bottom: 36px;
			&.fixedWidthLayout{
				width: calc(100% - 2*(var(--bs-gutter-x, 0.75rem)));
				left: var(--bs-gutter-x, 0.75rem);
			}
		}
		
	}
	

}