// Type
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	color: $headings-text;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 1.1;
	font-family: $heading-font-family;
}

h1, .h1 {
	font-size: 3.6rem;
}

h2, .h2 {
	font-size: 2.1rem;
}

h3, .h3 {
	font-size: 1.8rem;
}

h4, .h4 {
	font-size: 1.6rem;
}

h5, .h5 {
	font-size: 1.2rem;
}

h6, .h6 {
	font-size: 1rem;
}

a {
	transition: all .2s ease-out;
	color: $primary-color-dark;
	text-decoration: none;
}

a:hover,
a:focus {
	color: $primary-color;
	text-decoration: none;
}

.heading {
	margin-bottom: 4rem;
	font-size: 1.4rem;

	.title {
		margin-bottom: 1.6rem;
	}

	p {
		letter-spacing: -.015em;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.title {
	text-transform: uppercase;
}

.subtitle {
	border-bottom: 1px solid #e7e7e7;
	color: #282d3b;
	font: 700 1.6rem / 1 $second-font-family;
	margin-bottom: 3rem;
	padding: 0 0 1rem;
	text-transform: uppercase;
}

.light-title {
	margin-bottom: 2rem;
	font-weight: 300;
}

.text-primary {
	color: $primary-color !important;
}

@include mq(md) {
	h1, .h1 {
		font-size: 4.5rem;
	}
}

@include mq(lg) {
	h1, .h1 {
		font-size: 5rem;
	}
}
