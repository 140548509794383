// Base
html {
	font-size: 70%;
	font-size-adjust: 1;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	background-color: #fff;
	color: $body-text;
	font: normal 400 #{$font-size} / 1.4 $font-family;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;

	//&:not(.loaded) > *:not(.loading-overlay) {
	//  visibility: hidden;
	//}
}

::-moz-selection {
	color: #FFF!important;
    background: #000!important
}

::selection {
	color: #FFF!important;
    background: #000!important
}

p {
	margin-bottom: 1.5rem;
}

b,
strong {
	font-weight: 700;
}

em,
i {
	font-style: italic;
}

hr {
	max-width: 1730px;
	margin: 5.5rem auto 5.2rem;
	border: 0;
	border-top: 1px solid #dfdfdf;
}

sub,
sup {
	position: relative;
	font-size: 70%;
	line-height: 0;
	vertical-align: baseline;
}

sup {
	top: -.5em;
}

sub {
	bottom: -.25em;
}

img {
	display: block;
	max-width: 100%;
}

:focus {
	outline: 0;
}

.btn.focus,
.btn:focus,
.btn:active,
.btn:active:focus,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus,
button.focus,
button:focus,
button:active,
button:active:focus {
	box-shadow: none !important;
	outline: 0 !important;
}

.text-left{
	text-align: left !important;
}
.text-right{
	text-align: right !important;
}

// Animation for lazyload
@keyframes rotating {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}

@-webkit-keyframes bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

//.loading-overlay {
//  position: fixed;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  transition: all .5s ease-in-out;
//  background: #fff;
//  opacity: 1;
//  visibility: visible;
//  z-index: 999999;
//
//  .loaded > & {
//    opacity: 0;
//    visibility: hidden;
//  }
//}

.bounce-loader {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 70px;
	margin: -9px 0 0 -35px;
	transition: all .2s;
	text-align: center;
	z-index: 10000;

	.bounce1,
	.bounce2,
	.bounce3 {
		display: inline-block;
		width: 18px;
		height: 18px;
		border-radius: 100%;
		background-color: #CCC;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
		-webkit-animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
		animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
	}

	.bounce1 {
		-webkit-animation-delay: -.32s;
		animation-delay: -.32s;
	}

	.bounce2 {
		-webkit-animation-delay: -.16s;
		animation-delay: -.16s;
	}
}

@media (max-width: 991px) {
	html {
		font-size: 8px;
	}
	.mobile--column2 .col-6 {
		width: 100%!important;
	}
	.accordion {
		padding: 0px 15px!important;
	}
}
.faq--cat{
	margin-bottom: 100px!important;
}   
.accordion-button{
	  font-size: 1.5rem!important;
  }
  body p{
	  font-size: 14px!important;
  }
  ::selection {
    color: #000!important;
	background: #fbf7f3!important;
}
.accordion-button:not(.collapsed) {
    color: #000!important;
}
.fsearchDxp2{
	.product-thumb-info-image{
		img:nth-child(2){
		display: none;
		}
	}
	.quick-view{
		display: none;
	}
	h3{
		font-family: "MessinaSansRegular" !important;
    font-size: 16px;
    font-weight: 400;
	}
	.product-thumb-info{
		margin-bottom: 1rem !important;
		margin-top: 30px!important;
	}
	.text-default, .text-color-default {
		color: #777 !important;
		font-size: .7em !important;
		margin-bottom: 0.25rem !important;
	}
}

#header.headerLayoutTwo .header-nav-features .header-nav-features-cart .cart-info {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    margin-top: -4px;
    left: -17px;
    padding: 0;
    display: block;
    line-height: 1;
}
#header.headerLayoutTwo .header-nav-features .header-nav-features-cart .cart-info .cart-qty {
    position: absolute;
    top: -12px;
    right: -20px;
    width: 15px;
    height: 15px;
    display: block;
    font-size: 9px;
    font-weight: 600;
    color: #fff;
    background-color: #ed5348;
    text-align: center;
    line-height: 15px;
    border-radius: 20px;
    -webkit-box-shadow: -1px 1px 2px 0 rgb(0 0 0 / 30%);
    box-shadow: -1px 1px 2px 0 rgb(0 0 0 / 30%);
}
.header-top{
    padding: 6px 94px 6px !important;
}
.accountCartSearchWrap {
    align-items: center;
}
.searchCartWrap {
    align-items: center;
}
.header-top .simple-search .form-control{
    margin-bottom: 0;
}
.header-top .header__logged-out{
	padding-top: 0px !important;
}
.headerFixed .header-bottom {
    padding-bottom: 24px !important;
}
.header-top.section-dark .simple-search .form-control{
	border: none;
}
.headerLayoutTwo .menu li ul li a{
line-height: 1 !important;
}
@media screen and (min-width:360px) and (max-width:991px){
	.breadcrumb-nav {
		// margin-top: 110px !important;
	}
	.header {
		position: fixed !important;
		top: 0 !important;
	}
	.breadcrumb-nav .container {
		border-top: none !important;
	}
	#header.headerLayoutTwo .header-nav-features .header-nav-features-cart .cart-info{
		left: -14px;
	}
}

@media screen and (min-width:992px) and (max-width:1024px){
	.header-top{
		padding-left: 0 !important;
    padding-right: 0 !important;
	}
}

/* scrollbar styling */
/* width */
::-webkit-scrollbar {
	width: 6px; }
  /* Track */
  ::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #F5F5F5; }
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #605d5d!important;
	border-radius: 10px; }

/*Header updated*/
#header .header-nav-features .simple-search .btn img {
    height: 18px!important;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list {
    max-height: 270px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 13px;
    padding-right: 20px;
	padding-left: 0px!important;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li:first-child {
    margin-top: -15px;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li {
    position: relative;
    padding: 15px 0;
    border-bottom: 1px solid #EEE;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-image {
    float: right;
    border-color: #ededed;
    border-width: 1px;
    padding: 0;
    border: 1px solid #ddd;
    background-color: #fff;
    display: block;
    position: relative;
}
.mini-products-list .item img{
	height: 80px;
}
.mini-products-list .item .product-details i {
    font-size: 0.8rem!important;
}	
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-details {
    margin-left: 0;
    line-height: 1.4;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-details .product-name {
    margin: 15px 0 0;
    font-size: 0.9em;
    font-weight: 400;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-details a {
    letter-spacing: 0.005em;
    font-weight: 600;
    color: #696969;
}
#header .header-nav-features .header-nav-features-cart .mini-products-list li .product-details .btn-remove {
    z-index: 3;
    top: 5px;
    right: -5px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    color: #474747;
    border-radius: 100%;
    position: absolute;
    text-align: center;
    -webkit-box-shadow: 0 2px 3px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 20%);
    line-height: 20px;
    font-size: 10px;
}
.header-top i {
    margin-top: 3px;
    font-size: 0.8rem;
    color: #000;
}
#header .header-nav-features .header-nav-features-cart .totals {
    padding: 15px 0;
}
#header .header-nav-features .header-nav-features-cart .totals .label {
    float: left;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #474747;
}
#header .header-nav-features .header-nav-features-cart .totals .price-total {
    float: right;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    color: #474747;
}
#header .header-nav-features .header-nav-features-cart .actions .btn {
    display: inline-block;
    min-width: 125px;
    text-align: center;
    margin: 5px 0;
    float: right;
    font-size: 11.2px;
    font-size: 0.7rem;
    padding: 11.2px 16px;
    padding: 0.7rem 1rem;
    text-transform: uppercase;
    font-weight: 600;
}
.header-nav-features-dropdown .actions a {
    width: 125px!important;
    line-height: 21px!important;
    border-radius: 5px!important;
    font-weight: 600!important;
    padding: 0.7rem 1rem!important;
    text-transform: uppercase!important;
    font-size: 15px!important;
	font-family: "MessinaSansSemiBold", sans-serif !important;
}
.header-nav-features-dropdown .actions{
	display: flex!important;
    flex-direction: row!important;
    justify-content: space-between!important;
}
.product-thumb-info-list .price .sale {
    font-size: 19px!important;
    font-weight: 600;
}
.fsearchDxp2 .js-live-pricing {
    display: none;
}
.fsLogedIn .js-live-pricing {
    display: block!important;
}
.header-top .simple-search .form-control {
    padding-right: 9px!important;
}
.product-listing-container{
	.product{
		h2{
			margin: 0px!important;
			font-family: "MessinaSansRegular" !important;
			font-size: 16px!important;
			font-weight: 400!important;
		}
	}
}
.banner-title{
	font-weight: 300!important;
    font-size: 40px!important;
}
.section--form button[type="submit"]{
font-size: 16px;
}

.header-top .simple-search .form-control {
    font-size: 11px;
}