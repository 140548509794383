// Breadcrumb
.breadcrumb-nav {
	// border-bottom: 1px solid #dfdfdf;
	// border-top: 1px solid #dfdfdf;
	padding: 1rem 0;
	margin-top: 148px;
    float: none;
    width: 100%;
	.container {
		// max-width: 1295px!important;
		// border-top: 2px solid #000;
	}
}

.breadcrumb {
	margin-bottom: 0;
	padding: 1.25rem 0;
	border-radius: 0;
	background-color: transparent;
	
}

.breadcrumb-item {
	text-transform: capitalize;
	font-family: $font-family;
	letter-spacing: 0.05em;
	font-size: 12px;
	font-weight: 400;
	padding-right: 8px;
}

.breadcrumb-item + .breadcrumb-item {
	// padding-left: 2.2rem;
}

.breadcrumb-item + .breadcrumb-item:before {
	content: '\e81a';
	font-family: 'porto';
	padding-right: 2.2rem;
	font-size: 1.4rem;
	vertical-align: middle;
	margin-top: -.5rem;
	color: #000;
	font-size: 0px!important;
}

.breadcrumb-item a{
	color: #888;
	font-size: 12px;
	font-weight: 400;
}
.breadcrumb-item.active {
	color: #000;
}
.breadcrumb-item + .breadcrumb-item::before{
	font-size: 0px!important;
}

@media screen and (min-width:320px) and (max-width:991px) {
	.breadcrumb-nav{
		// margin-top: 68px;
	}
}