// Modals
.modal {
	form {
		margin: 0;

		.form-group {
			max-width: 480px;
		}

		.form-control {
			max-width: 100%;
		}
	}
}

.modal-body {
	padding: 1.5rem;
}

.modal-content {
	border-radius: 0;
	box-shadow: 0 0 12px 2px rgba(0, 0, 0, .35);
}

.modal-header,
.modal-footer {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	height: 80px;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
}

.modal-title {
	font-weight: 600;
}

.close {
	font-size: 2.2rem;
}

@include mq(sm) {
	.modal-content {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.modal-body {
		max-height: calc(100vh - 210px);
		padding-top: 2rem;
		overflow-y: auto;
	}

	.modal-header,
	.modal-footer,
	.modal-body {
		padding-right: 2em;
		padding-left: 2rem;
	}
}

@include mq(lg) {
	.modal-dialog {
		max-width: 800px;
	}

	.modal-header,
	.modal-footer,
	.modal-body {
		padding-right: 3rem;
		padding-left: 3rem;
	}
}

@include mq(xs, max) {
	.modal-open,
	.modal-open .modal {
		padding-right: 0 !important;
	}
}
