// Footer
.footer {
	background-color: #FBF7F3;
	.container,
	.container-fluid {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		width: 100%;
		// max-width: none;
		// padding: 0 3.125%;
		padding: 0px;
	}

	img {
		width: auto;
		height: auto;
		max-height: 80px;
	}

	ul.links {
		margin: 0 0 2.25rem;
		padding: 0;
		list-style: none;
	}
	p{
	font-size: 14px;
    font-weight: 400;
    color: #000000;
	margin-bottom: 5px;
	line-height: 21px!important;
	 a{
		color: #000000;
	 }
	}
}

.widget-newsletter-title {
	font-size: 1.8rem;
	font-weight: 700;
	text-transform: none;
	color: #fff;
	margin: 1rem 0;
}

p.widget-newsletter-content {
	font-size: 1.3rem;
	letter-spacing: 0.005em;
	color: #fff;
	margin: 1rem 0;
}

span.widget-newsletter-content {
	font-size: 1.6rem;
	letter-spacing: 0.005em;
	font-weight: 700;
	color: #fff;
}

.footer-middle {
	margin: 0px;
	// margin: 6rem 0 4rem;
	// padding-top: 6rem;
	padding-top: 39px;
	border-top: 1px solid #e7e7e7;
	margin-top: 0 !important;

	.home & {
		padding-top: 0;
		border-top: none;
	}

	img {
		margin-bottom: 4rem;
	}
}

.widget {
	margin-top: 0;

	.widget-title {
		text-transform: none;
		color: black;
		font-size: 1.6rem;
		font-weight: 700;
		margin-bottom: 1.5rem;
	}

	.links li {
		margin-bottom: .4rem;
	}

	a {
		color: #777;
		font: 1.3rem "Poppins";
	}
}

.widget-account {
	padding: 0 7rem;
}

a:hover,
a:focus,
a:active {
	color: black;
}

.contact-widget {
	margin: 0 0;

	.widget-title {
		font: 600 1.3rem "Poppins";
		color: $primary-color-dark;
		margin-bottom: 0;
	}

	a {
		font-weight: 400;
		color: #777;
	}

	a:hover {
		text-decoration: underline;
	}

	&.email a {
		color: $color-black;
		text-decoration: none;

		&:hover {
			color: $color-black!important;
			text-decoration: underline!important;
		}
	}
}

.footer .social-icons .social-icon {
	width: 20px;
	height: 20px;
	background-color: transparent;
	margin-top: 26px;
	margin-right: 1.5rem;

	i {
		font-size: 24px;
		color: black;

		&:before {
			margin: 0;
		}
	}

	&:hover i {
		color: $primary-color;
	}
}
.footer-bottom {
	// border-top: 1px solid #e1e1e1;
	// padding: 4.1rem 0 5rem;
	// padding-top: 27px;
	padding-bottom:0px!important;
	margin-bottom: 0;

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin: 0px auto;
	}
}

.footer-submit-wrapper {
	display: flex;
	width: 100%;
	max-width: 385px;

	input {
		background-color: #e2e2e0;
		font: 1.2rem "Poppins";
		color: #999;
		letter-spacing: .005em;
		border: none;
		margin-bottom: 0;

		&:active,
		&:focus {
			background-color: #f1f1f1;
		}
	}

	.btn {
		background-color: $color-primary-inverse;
		font: 700 1.2rem "Poppins";
		color: #fff;
		letter-spacing: .005em;
		border: none;

		&:hover {
			background-color: $color-primary;
		}
	}
}

// Scroll Top Button
#scroll-top {
	height: 40px;
	position: fixed;
	right: 10px;
	min-width: 50px;
	z-index: 9999;
	bottom: 0;
	background-color: #404040;
	font-size: 16px;
	color: #fff;
	text-align: center;
	line-height: 1;
	padding: 10px 10px 35px;
	visibility: hidden;
	opacity:0.75!important;
	border-radius: 4px 4px 0 0!important;
	transition: all .3s, margin-right 0s;
	-webkit-transform: translateY(40px);
	transform: translateY(40px);
}

#scroll-top:hover,
#scroll-top:focus {
	background-color: #3a4045;
}

#scroll-top.fixed {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
}
a#scroll-top i {
    padding-top: 4px;
    float: left;
    width: 100%;
}
.icon-angle-up:before {
    font-weight: 600!important;
}
@media (min-width: 992px) {
	.footer .col-lg-2 {
		-ms-flex: 0 0 16.6%;
		flex: 0 0 16.6%;
		max-width: 16.6%;
	}

	.footer .col-lg-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.footer .col-lg-4 {
		-ms-flex: 0 0 33.3%;
		flex: 0 0 33.3%;
		max-width: 33.3%;
	}

	.footer .col-lg-5 {
		-ms-flex: 0 0 41.6%;
		flex: 0 0 41.6%;
		max-width: 41.6%;
	}

	.footer-middle {
		margin-top: 6.2rem;
		// margin-bottom: 1.5rem;
	}

	.footer-bottom {
		flex-direction: row;
		align-items: flex-start;
		padding-bottom: 4.8rem;
	}

	.footer-copyright {
		margin-bottom: 0;
		margin-right: 0;
		margin-left: 0;
	}

	.footer-payments {
		margin-right: auto;
		margin-left: 0;
		margin-bottom: 0;
	}

	.footer-bottom .social-icons {
		margin-left: 5.4rem;
	}

	.footer-bottom .contact-info {
		margin-bottom: 0;
		margin-right: 0;
	}
}

@media (min-width: 1200px) {
	.footer-bottom .social-icons {
		margin-left: 9.2rem;
	}

	.contact-widget.follow {
		margin-top: 0;
	}

	// Prevents footer menu display correctly
	//.footer-middle {
	//  .container>.row>div {
	//    &:nth-child(odd) {
	//      max-width: 28.2%;
	//      flex: 0 0 28.2%;
	//    }
	//    &:nth-child(even) {
	//      max-width: 21.8%;
	//      flex: 0 0 21.8%;
	//    }
	//  }
	//}
}
@media screen and (min-width:769px) and (max-width:1199px){
	.socialicons__modifier{
		margin-bottom: 30px!important;
	}
}
@media (max-width: 768px) {
	.footer .container {
		max-width: 720px;
	}
	.footer-middle img {
		margin-bottom: 2rem;
	}

	.footer-middle {
		margin: 2rem 0;
		.socialicons__modifier{
			margin-bottom: 30px;
		}
	}

	.footer-middle .col-lg-6:nth-child(2) {
		margin-top: 2rem;
	}
}
.footermsg__modifier {
	margin-bottom: 26px;
	&::after{
		content: ".";
		width: 32px;
		height: 1px;
		background: #000;
		position: absolute;
		margin: 10px 0px;
		font-size: 0px!important;
	}
}
@media screen and (min-width:768px) and (max-width:991px) {
	.footer .container {
		padding-right: 3.125%!important;
		padding-left: 3.125%!important;
	}
}
@media screen and (max-width:767px) {
	.footer .container {
		padding-right: 3.125%!important;
		padding-left: 3.125%!important;
	}
}
