/*
 * 	Default theme - Owl Carousel CSS File
 */

$color-base:			#bfbfbf !default;
$color-white:			#FFF !default;
$color-gray:			#dfdfdf !default;

//nav

$nav-color: 			$color-base !default;
$nav-color-hover:		$primary-color !default;
$nav-font-size: 		2rem !default;
$nav-rounded: 			0 !default;
$nav-margin: 			0 !default;
$nav-padding: 			4px 7px !default;
$nav-background: 		transparent !default;
$nav-background-hover:	transparent !default;
$nav-disabled-opacity: 	0.5 !default;

//dots

$dot-width:					.8rem !default;
$dot-height:				.8rem !default;
$dot-rounded:				50% !default;
$dot-margin: 				4px 4px !default;
$dot-border:				rgba(0, 0, 0, .1) !default;
$dot-border-light:			rgba(255, 255, 255, .1) !default;
$dot-border-active:			#252525 !default;
$dot-border-light-active: 	#fff !default;
$dot-background:			rgba(0, 0, 0, .25) !default;
$dot-background-active:		#252525 !default;
$dot-background-light:		rgba(255, 255, 255, .4) !default;
$dot-background-light-active:		#fff !default;

@import 'theme';
