// Mobile Menu
.mobile-menu-container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 250px;
	transform: translateX(-100%);
	transition: all .35s;
	background-color: #FBF7F3;
	font-size: 1.6rem;
	line-height: 1.5;
	box-shadow: .1rem 0 .6rem 0 rgba(50, 50, 50, .65);
	visibility: hidden;
	z-index: 1001;
	overflow-y: auto;

	.mmenu-active & {
		transform: translateX(0);
		visibility: visible;
	}

	.social-icons {
		-ms-flex-pack: center;
		justify-content: center;
		margin-bottom: 0;
	}

	.social-icon {
		width: 3rem;
		height: 3rem;
		margin-bottom: 0;
		border: 0;
		background-color: transparent;
		font-size: 1.5rem;
		color: #868482;

		& + .social-icon {
			margin-left: .3rem;
		}


		&:hover,
		&:focus {
			background-color: transparent;
			color: #fafafa;
		}
	}
}

.mobile-menu-wrapper {
	position: relative;
	padding: 4.2rem 0 3rem;

	.header-search .header-search-wrapper {
		display: flex;
		position: relative;
		left: 0;
		border: none;
		margin: 4rem 2rem;
		max-width: 210px !important;
		min-width: auto;

		&:before {
			display: none;
		}

		.form-control {
			width: 100%;
			border: none;
		}

		.btn {
			flex: 0 0 60px;
			border: none;
			border-radius: 0 25px 25px 0;
		}
	}
}

.mobile-menu-close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	padding: .4rem;
	color: #000;
	font-size: 1.3rem;
	line-height: 1;
	cursor: pointer;
	z-index: 9;
}

.mobile-menu-overlay {
	display: block;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: all .4s;
	background-color: rgba(0, 0, 0, .5);
	opacity: 0;
	visibility: hidden;
	z-index: 1000;
}

.mmenu-active .mobile-menu-overlay {
	opacity: 1;
	visibility: visible;
}

.mobile-nav {
	margin: 0 0 2rem;
	padding: 0;
}

.mobile-menu {
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #dfdfe0;
	list-style: none;

	> li {
		> a {
			text-transform: uppercase;
		}
	}

	li {
		display: block;
		position: relative;
		border-top: 1px solid #dfdfe0;

		a {
			display: block;
			position: relative;
			margin-right: 4.5rem;
			padding: 1.1rem 0 1.1rem 1.5rem;
			color: #868482;

			&:hover,
			&:focus {
				color: #000;
				font-weight: bold;
				text-decoration: none;
			}
			
		}

		&.open,
		&.active {
			> a {
				color: #000;
				font-weight: bold;
			}
		}

		ul {
			display: none;
			margin: 0;
			padding: 0;

			li a {
				padding-left: 2.5rem;
			}

			ul {
				li a {
					padding-left: 3.5rem;
					i{
						color: #000;
					}
				}
			}
		}
	}
}

.mmenu-btn {
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	top: 50%;
	right: -3.8rem;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 3rem;
	height: 3rem;
	margin-top: -1.5rem;
	border-radius: 0;
	outline: none;
	background-color: transparent;
	color: #868482;
	font-size: 1.5rem;
	cursor: pointer;

	&:after {
		display: inline-block;
		margin-top: -2px;
		font-family: 'porto';
		content: '\e81c';
	}

	.open > a > &:after {
		content: '\e81b';
	}
}
.open > a > .mmenu-btn{
	color: #000;
}