// Button Background
@mixin buttonBackground($color, $textColor: #fff) {

	background-color: $color;
	border-color: $color $color darken($color, 10%);
	color: $textColor;

	&:hover,
	&.hover {
		background-color: lighten($color, 7.5%);
		border-color: lighten($color, 10%) lighten($color, 10%) $color;
		color: $textColor;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 3px fade($color, 50%);
	}

	&.disabled,
	&:disabled {
		background-color: $color;
		border-color: $color $color darken($color, 10%);
	}

	&:active,
	&.active {
		background-color: darken($color, 7.5%) !important;
		background-image: none !important;
		border-color: darken($color, 10%) darken($color, 10%) darken($color, 20%) !important;
	}

}

@mixin buttonOutlineBackground($color, $textColor: #fff) {

	color: $color;
	background-color: transparent;
	background-image: none;
	border-color: $color;

	&:hover,
	&.hover {
		color: $textColor;
		background-color: $color;
		border-color: $color;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 3px fade($color, 50%);
	}

	&.disabled,
	&:disabled {
		color: $color;
		background-color: transparent;
	}

	&:active,
	&.active {
		color: $textColor !important;
		background-color: $color !important;
		border-color: $color !important;
	}
}

// Drop shadows
@mixin box-shadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	box-shadow: $shadow;
}