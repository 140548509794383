// Banners
.banner {
	display: block;
	position: relative;
	margin-bottom: 0;
	background-color: #ccc;

	&.banner-cat {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		height: 220px;
		background-size: cover;
		background-position: center right;
		background-repeat: no-repeat;
		background-color: #ccc;
		margin-bottom: 0;
	}

	> a {
		display: block;
	}

	img {
		display: block;
		width: 100%;
		max-width: none;
		height: auto;
	}

	.banner-content {
		display: inline-block;
	}

	.banner-title {
		color: #fff;
		font: 700 3rem/1.1 $second-font-family;
		margin-bottom: 1.8rem;
		letter-spacing: -.025rem;
		text-transform: uppercase;
		text-shadow: 1px 1px 2px $primary-color-dark;
	}

	.banner-subtitle {
		color: #fff;
		font: 700 1.5rem/1.1 $font-family;
		letter-spacing: 0;
		text-transform: uppercase;
		margin-bottom: .4rem;
		text-shadow: 1px 1px 2px $primary-color;


		span {
			font-family: $second-font-family;
			font-size: 2.4rem;
		}
	}

	.btn {
		padding-top: 1.1rem;
		padding-bottom: 1.1rem;
		min-width: 100px;
		border-radius: .2rem;
	}
}

.banner-image {
	> a {
		position: relative;

		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: rgba($secondary-color, .3);
			transition: all 0.3s ease;
			opacity: 0;
			visibility: hidden;
		}

		&:hover,
		&:focus {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

@include mq(md) {
	.banner {
		&.banner-cat {
			height: 260px;
		}

		.banner-title {
			font-size: 3.8rem;
		}

		.banner-subtitle {
			font-size: 1.6rem;

			span {
				font-size: 2.5rem;
			}
		}
	}
}

@include mq(lg) {
	.banner {
		&.banner-cat {
			height: 300px;
		}

		.banner-title {
			font-size: 4.6rem;
		}

		.banner-subtitle {
			font-size: 1.8rem;

			span {
				font-size: 2.6rem;
			}
		}
	}
}


@media only screen and (min-width: 1300px) {
	i.icon-left-open-big, i.icon-right-open-big {
		font-size: 52px;
	}

	i.icon-left-open-big {
		margin-left: -70px;
	}

	i.icon-right-open-big {
		margin-right: -70px;
	}
}

@media only screen and (max-width: 1299px) {
	i.icon-left-open-big, i.icon-right-open-big {
		font-size: 30px;
	}

	i.icon-left-open-big {
		margin-left: -20px;
	}

	i.icon-right-open-big {
		margin-right: -20px;
	}
}


@media only screen and (max-width: 800px) {
i.icon-left-open-big, i.icon-right-open-big {
    display: none;
}
}

@media only screen and (min-width: 991px) {
	.home-top.gap {
		padding-top: 105px;
	}
}