.accordion-item {
	border: 1px solid #ddd;
	border-radius: 0;
	font-size: $font-size;
}

.accordion-header {
	@include clearfix;
	margin: 0;
	padding: 1.2rem 1.5rem;
	border-radius: 0;
	border-color: #ddd;
	background-color: #f5f5f5;
	color: $headings-text;
	font-weight: 700;
	line-height: 1.5;
	text-transform: uppercase;

	.accordion-button {
		background-color: inherit;
	}
}
