// Testimonials
.testimonial-owner {
	display: flex;
	display: -ms-flexbox;
	align-content: center;
	-ms-flex-align: center;

	figure {
		max-width: 60px;
		margin-right: 25px;
	}

	h4 {
		display: block;
		margin-bottom: .5rem;
		padding-top: .7rem;
		color: #111;
		font-size: 1.4rem;
		text-transform: uppercase;
	}

	span {
		display: block;
		color: #666;
		font-size: 1.2rem;
		text-transform: uppercase;
	}
}

.testimonial {
	blockquote {
		position: relative;
		margin-left: 15px;
		padding: 1rem 2rem;
		font-size: 1.4rem;
		font-style: normal;
		line-height: 1.8;

		&:before {
			position: absolute;
			top: 0;
			left: -1rem;
			color: $primary-color;
			font-family: 'porto';
			font-size: 2.1rem;
			font-weight: 400;
			line-height: 1;
			content: '\e842';
		}
	}
}

@include mq(sm) {
	.testimonial {
		blockquote {
			margin-left: 85px;
			padding: 1.5rem 3rem 1.5rem 2rem;
		}
	}
}
