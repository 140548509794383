// Category
.sidebar-shop {
	font-size: $sidebar-font-size;

	.widget {
		@include clearfix;
		margin: 0;
		padding-bottom: 5.5rem;

		.config-swatch-list {
			margin-top: .3rem;

			li {
				display: -ms-flexbox;
				display: flex;
				-ms-flex-align: center;
				align-items: center;
				margin-right: 0;
				margin-bottom: 1.2rem;
				font-size: 1.2rem;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					margin-right: 1.5rem;
					width: auto;
				}

				&.active a:before {
					left: 15%;
				}
			}
		}
	}

	.widget-title {
		margin: 0;
		color: #000;
		font: 700 1.2rem/1.1 $second-font-family;
		letter-spacing: .05em;
		text-transform: uppercase;
		padding-bottom: 1rem;
		border-bottom: .2rem solid #dfdfdf;

		a {
			color: #000;
			display: block;
			position: relative;

			&:hover,
			&:focus {
				color: inherit;
				text-decoration: none;
			}

			&:before,
			&:after {
				content: '';
				position: absolute;
				display: inline-block;
				right: .5rem;
				top: 50%;
				width: 10px;
				height: 2px;
				background-color: #000;
				margin-top: -1px;
				transition: all .35s;
			}

			&.collapsed:after {
				transform: rotate(-90deg);
			}
		}
	}

	.widget-body {
		@include clearfix;
		padding-top: 1.7rem;
		padding-left: 1.5rem;
	}

	.widget-featured {
		position: relative;

		.widget-body {
			padding-top: 2.3rem;
		}

		.product-sm:last-child {
			margin-bottom: 0;
		}
	}
}

.widget {
	.owl-carousel .owl-nav {
		position: absolute;
		top: -4.2rem;
		right: -.4rem;

		button.owl-next,
		button.owl-prev {
			padding: 0 .4rem !important;
			border-radius: 0;
			color: $headings-text;
			font-size: 2rem;
			line-height: 1;
		}

		i:before {
			width: auto;
			margin: 0;
		}
	}
}

.cat-list {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin-bottom: 1.4rem;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: #000;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
}

.config-size-list {
	margin: 0;
	padding: 0;
	font-size: 0;
	list-style: none;
}

.config-size-list li {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: .8rem;
	margin-bottom: .8rem;
}

.config-size-list a {
	position: relative;
	display: block;
	width: 3.4rem;
	height: 3rem;
	transition: all .3s;
	font-weight: 600;
	text-align: center;
	font-size: 1.4rem;
	line-height: 2.8rem;
	border: 1px solid #e9e9e9;
	text-decoration: none;
	color: $body-text;

	&:hover,
	&:focus,
	&.active {
		border-color: $primary-color;
		background-color: $primary-color;
		color: #61605a;
		text-decoration: none;
	}
}

.price-slider-wrapper {
	padding-top: 2rem;
}

.filter-price-action {
	@include clearfix;
	display: flex;
	display: -ms-flexbox;
	align-items: center;
	-ms-flex-align: center;
	margin-top: 2.5rem;
	padding-bottom: .4rem;

	.filter-price-text {
		margin-left: auto;
	}

	.btn {
		padding: .3rem .8rem;
		font-size: 1.2rem;
		letter-spacing: .08em;
		min-width: 0;
	}
}

.config-swatch-list {
	margin: 1.5rem 0 0;
	padding: 0;
	font-size: 0;
	list-style: none;
}

.config-swatch-list li {
	margin-right: .4rem;
	margin-bottom: .3rem;

	a {
		position: relative;
		display: flex;
		align-items: center;
		width: 2.2rem;
		height: 2.2rem;
		color: #000;

		span:last-child {
			padding-top: .3rem;
			color: #7a7d82;
			font-size: 1.2rem;
		}
	}

	.color-panel {
		display: inline-block;
		width: 1.7rem;
		height: 1.7rem;
		border: 1px solid #fff;
		transition: all .3s;
		margin-right: 1.5rem;
	}

	&:hover span:last-child {
		text-decoration: underline;
	}
}

.config-swatch-list li.active a:before {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	color: #fff;
	font-family: 'porto';
	font-size: 1.1rem;
	line-height: 1;
	content: '\e84e';
}

.config-swatch-list a:hover .color-panel,
.config-swatch-list a:focus .color-panel,
.config-swatch-list li.active .color-panel {
	box-shadow: 0 0 0 .1rem #dfdfdf;
}

.boxed-slider .category-slide {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	height: 300px;
}

.category-grid .home-product {
	border: none;
	box-shadow: none;
	transition: all .3s ease-out;
	margin-top: 4rem;
	padding: 0;

	&:hover {
		box-shadow: none;
		transform: translateY(-3rem);

		figure {
			box-shadow: none;
		}
	}
}

.boxed-slide-content {
	margin-bottom: 0;
	background-color: transparent;
	z-index: 3;

	.btn {
		display: inline-block;
	}
}

.boxed-slider .owl-dots {
	position: absolute;
	right: 0;
	bottom: 2.5rem;
	left: 0;
}

.horizontal-filter {
	margin-bottom: 18px;
	padding: 1.2rem 1rem;
	background-color: #f4f4f4;

	.price-sort {
		position: relative;

		label {
			font-size: 1.2rem;
		}

		.input-price {
			display: block;
			width: 100%;
			margin-bottom: 10px;
			padding: 5px 8px;
			line-height: 1.4;
			box-shadow: none;
		}

		&.opened:before {
			display: block;
		}
	}
}

.sort-list li {
	padding: 7px 0;
	font-size: 1.2rem;
	text-transform: uppercase;
}

.filter-toggle {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 1rem;

	a {
		display: inline-block;
		position: relative;
		width: 46px;
		height: 26px;
		margin-left: 8px;
		border-radius: 13px;
		background: #e6e6e6;
		text-decoration: none;

		&:before {
			position: absolute;
			left: 0;
			width: 42px;
			height: 22px;
			transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
			-webkit-transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
			transition: all .3s linear;
			border-radius: 11px;
			background-color: #fff;
			content: '';
		}

		&:after {
			position: absolute;
			left: 0;
			width: 22px;
			height: 22px;
			transform: translate3d(2px, 2px, 0);
			-webkit-transform: translate3d(2px, 2px, 0);
			transition: all .2s ease-in-out;
			border-radius: 11px;
			background-color: #fff;
			box-shadow: 0 2px 2px rgba(0, 0, 0, .24);
			content: '';
		}
	}

	&.opened {
		a {
			background-color: $primary-color;
		}

		a:before {
			-webkit-transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
			transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
		}

		a:after {
			-webkit-transform: translate3d(22px, 2px, 0);
			transform: translate3d(22px, 2px, 0);
		}
	}
}

@include mq(sm) {
	.horizontal-filter {
		padding: 1.2rem 2rem;
	}
}

@include mq(lg) {
	.products-body {
		overflow: hidden;

		.sidebar-shop {
			left: -25%;
			transition: all .3s linear 0s;
			visibility: hidden;
			z-index: -1;
		}
	}

	.sidebar-opened .sidebar-shop {
		left: 0;
		visibility: visible;
		z-index: 0;
	}

	.main-content {
		margin-left: -25%;
		transition: all .3s linear 0s;
	}

	.sidebar-opened .main-content {
		margin-left: 0;
	}

	main:not(.sidebar-opened) .main-content {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;

		.col-md-4 {
			max-width: 25%;
		}
	}

	.filter-sorts {
		padding: 12px;

		.toolbox-left {
			position: relative;
		}

		.toolbox-item {
			margin-right: 1rem;
		}

		.toolbox-item.toolbox-sort {
			margin: 0 1rem 0 0;
			background: #fff;
		}

		.toolbox-item.toolbox-show {
			margin-left: auto;
			font-size: 1.2rem;
		}

		select {
			min-width: 140px;
			border: 0;
			text-transform: uppercase;
		}

		.layout-modes {
			margin-left: .5rem;
		}

		.filter-toggle {
			display: none;
		}

		.sidebar-shop {
			left: 0;
			visibility: visible;
			z-index: 2;
		}
	}

	.filter-price-form, .sort-list {
		display: none;
	}

	.filter-price-form {
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 220px;
		margin-top: 9px;
		padding: 10px 15px;
		background-color: #fff;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
		z-index: 99;
	}

	.sort-list {
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 220px;
		margin-top: 9px;
		padding: 10px 15px;
		background: #fff;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
		z-index: 99;
	}

	.sort-menu-trigger {
		display: block;
		min-width: 140px;
		height: 34px;
		padding-left: .8rem;
		color: #7a7d82;
		font-size: 1.2rem;
		line-height: 34px;
		text-transform: uppercase;
		z-index: 9;

		&:hover, &:focus {
			text-decoration: none;
		}

		&:before {
			display: none;
			position: absolute;
			top: 100%;
			left: 21px;
			margin-top: 1px;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #fff;
			border-left: 10px solid transparent;
			content: '';
			z-index: 999;
		}
	}

	.toolbox-item.opened {
		.sort-list, .filter-price-form, .sort-menu-trigger:before {
			display: block;
		}
	}
}

.home-product.home-product-list {
	display: flex;
	margin: 1rem 0;
	padding: 2rem 1rem;

	figure {
		margin-right: 1rem;
		margin-bottom: 0;
	}

	.product-details {
		margin-left: 1rem;
	}

	.category-wrap {
		max-width: 25%;

		h2 {
			font-size: 1.3rem;
		}

		a i {
			font-size: 1.5rem;
		}
	}

	.product-title a {
		font: 400 1.8rem "Poppins";
	}

	.product-description {
		font-size: 1.6rem;

		a {
			color: #ec7200;
		}

		a:hover {
			text-decoration: underline;
		}
	}

	&:hover {
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	}
}

@include mq(lg, max) {
	.sort-menu-trigger {
		display: block;
		margin-bottom: 1.5rem;
		font-size: 14px;
		font-weight: 600;
		line-height: 1.4;
		text-transform: uppercase;
	}

	.filter-sorts .toolbox-sort.select-custom {
		display: block;
		margin: 2rem;

		&:after {
			content: none;
		}
	}

	.sidebar-shop .widget {
		padding: 3rem 2rem 0;
	}
}
